import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';


export const ContainerAtividadeDestaque = styled.div`
  background-color: white; 
  /* margin: 10px 10px 10px 0; */
  line-height: 1em;
  margin-right: 20px;
  margin-top: 5px;
  min-width: 200px;
  /* min-height: 15rem; */
  
  cursor: pointer;

  /* &:hover ${AtividadeLink} {
    transform: scale(1.2);
    transition: all 0.375s ease-out;
  } */

  /* &:hover  {
    transform: scale(1.2);
    transition: all 0.375s ease-out;
  } */

  /* &:hover ${ContainerDescription} {
    
    display: block; 
    
  } */

`

export const AtividadeLink = styled(Link)`
  text-decoration: none;
  &:hover { 
    text-decoration: none;
  }
  
`;

export const AtividadeLink2 = styled.div`
  
`;


export const ContainerDescription = styled.div`
  /* display: none;  */
  padding: 10px;

  /* ${ContainerAtividadeDestaque}:hover & {
    display: block; 
    height: 100%;
  } */

`

export const PlaceHolderImage = styled.img`
  width: 200px;
  content: url('../../assets/images/capaplaceholder2.jpg')

`