import { favsConstants } from '../constants/favsConstants'

const INITIAL_STATE = {
  favs: {},
  fetching: true
  
};


// const applySetFavs = (state, action) => {
//   return ({
//     ...state, 
//     favs: action.favs,
//   });
// }

const applyResetFavs = () => {
  return ({
    ...INITIAL_STATE, 
  });
}

// const applyRemoveFav = (state, action) => {
  
//   if (state.favs) {
//     const ativId = action.atividade.atividadeId;
//     const { [ativId] : removedFav, ...restFavs } = state.favs.atividades;
//     const newFavs = { ...state.favs, ...restFavs}
//     return ({
//       ...state, 
//       favs: { ...state.favs, atividades: {...newFavs} }
//     });  
//   }
//   return (state);
// }

// const applyAddFav = (state, action) => {
//   const oldFavs = state.favs && state.favs.atividades ? state.favs.atividades : []
//   const newStateFavs = { ...oldFavs, ...action.atividade }
//   return ({
//     ...state, 
//     favs: { ...state.favs, atividades: { ...newStateFavs }}
//   });
// }




// const applyRemoveFav = (state, action) => {
// 	// console.log("​applyRemoveFav -> action", action)
  
//   if (state.favs) {
//     console.log('state favs', state)
//     const favsAgenda = state.favs[action.agendaId] || [];
// 		console.log("​applyRemoveFav -> favsAgenda ANTES", favsAgenda)
    
//     pull(favsAgenda, action.atividadeId);
// 		console.log("​applyRemoveFav -> favsAgenda DEPOIS", favsAgenda)
    
//     return ({
//       ...state, 
//       favs: { ...state.favs, [action.agendaId]:  favsAgenda  }
//     });  
//   }
//   return (state);
// }

// const applyAddFav = (state, action) => {
// 	console.log("​applyAddFav -> state", state)
// 	console.log("​applyAddFav -> action", action)
//   const agendaId = action.agendaId;

//   const oldFavs = state.favs && state.favs[agendaId] ? state.favs[agendaId] : []

//   const newStateFavs = { ...oldFavs, ...action.atividadeId }

//   return ({
//     ...state, 
//     favs: { ...state.favs, [agendaId]: newStateFavs } 
//   });
// }


function favsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    
    case favsConstants.FAV_SET: {
      return {
        favs: action.favs, 
        fetching: false
      }
    }
    case favsConstants.FAV_FETCHING : {
      return {
        fetching: true
      }
    }
    case favsConstants.FAV_RESET: {
      return applyResetFavs(state);
    }

    // case 'FAV_REMOVE': {
    //   return applyRemoveFav(state, action);
    // }
    // case 'FAV_ADD': {
    //   return applyAddFav(state, action);
    // }

    // case favsConstants.FAV_LIKE: {
    //   return applyRemoveFav(state, action);
    // }
    // case favsConstants.FAV_DISLIKE: {
    //   return applyAddFav(state, action);
    // }

    default: return state;
  }
}

export default favsReducer;