// import React from 'react'

// const ModalActivity = ({open}) => {
  
//   return (
//     <>  
//     </>
//   )
// }

// export default ModalActivity



import React, { Fragment, useEffect, useRef } from "react";
import * as S from "./styles";

const ModalActivity = ({ children, active, hideModal }) => {


  // for enable/disable closing on press esc 
  useEffect(() => {
    const close = (e) => {
      if(e.keyCode === 27 && active === true){
        hideModal()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  },[active])

  // for enable/disable scroll on modal background
  useEffect(() => {
    if (active === true) {
      document.body.style.overflow = "hidden";
      return () => {
        document.body.style.overflow = "scroll"
      };
    }
    
  }, [active]);


  return (
    <Fragment>
      {/* {active && ( */}
        {/* <S.ModalBlock active={active} ref={ref}> */}
        <S.ModalBlock active={active}>
          <S.ModalOverlay onClick={() => hideModal()}></S.ModalOverlay>
          <S.ModalContainer>
            <S.ModalHeader>
              {/* <S.ModalTitle>{title}</S.ModalTitle> */}
              <S.ModalClose onClick={() => hideModal()}>X</S.ModalClose>
            </S.ModalHeader>
            <S.ModalBody>{children}</S.ModalBody>
          </S.ModalContainer>
        </S.ModalBlock>
      {/* )} */}
    </Fragment>
  );
};
export default ModalActivity;
