import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faMapMarkerAlt, faClock, faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import mainColors from '../../constants/color'

import ScrollManager from '../ScrollManager';
import FavButton from '../FavButton';

import { getAgendaAnyway, getAtividadeAnyway } from '../../services/agendaService'

import ReactLoading from 'react-loading';

import ReactGA from 'react-ga'

import PreloadImage from 'react-preload-image';
import './Atividade.css'


import {Helmet} from "react-helmet";

import {
  WhatsappShareButton,
  WhatsappIcon
} from "react-share";

// const diaLocal = (evento, index) => {

//   let data = new Date(evento.dateTime.seconds * 1000);
//   let dataString = 'Dia ' + moment(data).format('DD[,] dddd[, às] HH[h]');
//   let horas = moment(data).format('mm');

//   dataString = horas !== '00' ? dataString + horas : dataString;

//   let localString = 'Local: ' + evento.local;
  
//   return (
//     <div key={index} className='pt-1 py-2'>
//       <div><FontAwesomeIcon icon={faCalendar} fixedWidth/> {dataString}</div>
//       <div><FontAwesomeIcon icon={faMapMarkerAlt} fixedWidth/> {localString}</div>
//     </div>
//   )

// }

const diaLocal = (evento, index) => {

  let data = new Date(evento.dateTime.seconds * 1000);
  let dataString = 'Dia ' + moment(data).format('DD/MM/YYYY[,] dddd');
  let horas = moment(data).format('HH[h]');
  let minutos = moment(data).format('mm');

  const horaString = minutos !== '00' ? horas + minutos : horas;

  // let localString = 'Local: ' + evento.local;
  
  return (
    <div key={index} className='pt-1 py-2 activity_text'>
      {/* <div><FontAwesomeIcon color={mainColors.redBNB} icon={faCalendar} fixedWidth/> {dataString}</div>
      <div><FontAwesomeIcon color={mainColors.redBNB} icon={faClock} fixedWidth/> {horaString}</div>
      <div><FontAwesomeIcon color={mainColors.redBNB} icon={faMapMarkerAlt} fixedWidth/> {localString}</div> */}

      <div><FontAwesomeIcon color={mainColors.orangeBNB} icon={faCalendar} fixedWidth/> {dataString}</div>
      <div><FontAwesomeIcon color={mainColors.orangeBNB} icon={faClock} fixedWidth/> {horaString}</div>
      <div><FontAwesomeIcon color={mainColors.orangeBNB} icon={faMapMarkerAlt} fixedWidth/> {evento.local}</div>
      {/* <div><FontAwesomeIcon color={mainColors.orangeBNB} icon={faMapMarkerAlt} fixedWidth/> {localString}</div> */}
    </div>
  )

}


const tituloAtividade = (atividade) => {
  if (atividade.nome === atividade.grupoArtista) 
    return (
      <div className="activity_title">{atividade.nome} ({atividade.origemArtista})</div>
    )
  return (
    <div>
      <div className="activity_title">{atividade.nome}</div>
      <div className="activity_text">{atividade.grupoArtista} ({atividade.origemArtista})</div>
    </div>
  )
}


class Atividade extends Component {


  constructor(props) {
    super(props);
    this.state = {
      agenda: null, 
      atividade: null
    }
  }
  
  componentDidMount = async (prevProps, prevState) => {
  // componentWillReceiveProps = async () => {
    window.scrollTo(0, 0);
    let atividade = null
    let agenda = null
    let isFav = null
    
    if (this.props.location.state) {
      atividade = this.props.location.state.atividade;
      agenda    = this.props.location.state.agenda;
      isFav     = this.props.location.state.fav;
    }
      
      

    // const { agendaId } = this.props.location.state;
    // const { atividadeId } = this.props.location.state;

    const { agendaId } = this.props.match.params;
    const { atividadeId } = this.props.match.params;
    // console.log("🚀 ~ file: Atividade.js:122 ~ //componentWillReceiveProps= ~ atividadeId:", atividadeId)
		

    if (!agenda || agenda === undefined) {
      // console.log('pegando agenda novamente')
      agenda = await getAgendaAnyway(agendaId);
      // console.log("🚀 ~ file: Atividade.js:127 ~ //componentWillReceiveProps= ~ agenda:", agenda)
    }

    if (!atividade || atividade === undefined) {
      atividade = await  getAtividadeAnyway(agendaId, atividadeId);
      // console.log("🚀 ~ file: Atividade.js:131 ~ //componentWillReceiveProps= ~ atividade:", atividadeId)
    }

    this.setState({
      agenda, 
      atividade,
      isFav
    })

    ReactGA.event({
      category: 'Atividade',
      action: `Visit Atividade ${agenda.ccbnb.toUpperCase()} - ${agenda.nome.toUpperCase()}`,
      label: `${atividade.nome.toUpperCase()}`
    });
  }
  

  render() {

    // let { atividade, agenda } = this.props.location.state;

    // const { agendaId } = this.props.location.state;
    // const { atividadeId } = this.props.location.state;
    // if (!agenda || agenda === undefined) {
    //   agenda = getAgendaAnyway(agendaId);
    // }

    // if (!atividade || atividade === undefined) {
    //   atividade = getAtividadeAnyway(agendaId, atividadeId);
    // }


    const { atividade, agenda, isFav } = this.state;
    
    // console.log("🚀 ~ file: Atividade.js:166 ~ render ~ atividade:", atividade)

    if (!atividade ||  !agenda || agenda === undefined || atividade === undefined ) {      
      return (
        <div style={{ minHeight: '80vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
          <ReactLoading type={'spin'} color={mainColors.orangeBNB} height={'20%'} width={'20%'} />  
        </div>        
      )

    }

    const titulo = tituloAtividade(atividade)

    if ( agenda && atividade ) {
			
      let { grupoArtista, nome } = atividade;

      // let complete_address = `https://agendaccbnb.com.br/agenda/${agenda.id}/atividade/${atividade.id}`
      let complete_address = `https://agendaccbnb.com.br/agenda/${agenda.id}/atividade/${atividade.atividadeId}`
  
      return (
        <div className='activity_container py-3'>
          
          <Helmet>
            <title>Agenda CCBNB - {atividade.nome}</title>
            <meta name="title" property="og:title" content={`Agenda CCBNB - ${atividade.nome}`} />
            <meta name="description" property="og:description" content={atividade.release} />
            
            <meta name="image" property="og:image:secure_url" content={atividade.imagem} />
            <meta name="og:image" property="og:image" content={atividade.imagem} />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />


            <meta name="type" property="og:type" content="article" />
            <meta property="og:url" content={complete_address} />
          </Helmet>

          <ScrollManager scrollKey={`atividade-${atividade.id}`} />
          <div className='p-2 mb-3 container' style={{fontSize: 15}}>
  
            {/* <button className='btn btn-outline-primary mb-3' onClick={this.props.history.goBack}>Voltar</button> */}
            <Link 
              className='btn btn-sm btn-outline-orange-bnb mb-3'
              to={{
                pathname: `/agenda/${agenda.id}`,
                state: {
                  agenda
                }
              }} 
            >
            <FontAwesomeIcon icon={faArrowLeft} />{' '}
            Voltar para Agenda
            {/* Para Agenda */}
            </Link>
  
            
            <div className='py-1'>
              {/* <div className="activity_title">{atividade.nome}</div>
              
              { grupoArtista !== nome && 
                <div className="activity_text">{atividade.grupoArtista}</div>
              }  */}

              {titulo}
            </div>
            
  
            {/* { !atividade.youtube && atividade.imagem && <img style={{ height: '50%', maxHeight: 500,  width: '100%', objectFit: 'cover'}} src={atividade.imagem} alt={`imagam atividade ${atividade.nome}`} className="py-2" /> }  */}
            { 
              !atividade.youtube && atividade.imagem && 

              // <div className='mx-auto' style={{width: '50vw'}} >
              // <div className='mx-auto wrapper' >
                <PreloadImage
                  className="preimage"
                  src={atividade.imagem}
                  alt={`imagam atividade ${atividade.nome}`}
                  lazy
                  duration="300ms"
                />
              // </div>
              
            } 
  
            { 
              atividade.youtube && 
              <div className='text-center'>
                <iframe width="100%" height="300vh" src={`https://www.youtube.com/embed/${atividade.youtube}`} 
                  title={atividade.youtube}
                frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen={true}></iframe>
              </div>
              
            }
  
            {/* antigo */}
            {/* <div className="activity_text"><b>{atividade.area} - {atividade.programa}</b></div>  */}
            
            {/* <div className="activity_text"><b><small>{atividade.area}</small> {atividade.programa}</b></div>  */}
  
            <div className="activity_text"><small>{atividade.area}</small></div>
            <div className="activity_text"><b>{atividade.programa}</b></div>

            {/* <div className="activity_text"><small><b>Área: </b>{atividade.area.toUpperCase()}</small></div> */}
            
            {/* <div className="activity_text"><small><b>Área: </b>{atividade.area}</small></div>
            <div className="activity_text"><small><b>Programa: </b>{atividade.programa}</small></div> */}

            {/* <div className="activity_text"><b>Área: </b>{atividade.area}</div>
            <div className="activity_text"><b>Programa: </b>{atividade.programa}</div> */}

            
            
            { atividade.eventos.map((evento, index) => diaLocal(evento, index)) }
            <div className='my-2'>
              <FavButton 
                isFav={isFav}
                agenda={agenda}
                atividade={atividade}
              />
            </div>
            {/* <div className="activity_text" style={{textAlign:"justify"}} > */}
            <div className="activity_text">
              {atividade.release}
            </div>  
            <div className="activity_text">Duração: {atividade.duracao}min.</div>
            <div className="activity_text">Classificação: {atividade.classificacao}</div>  

            <div className="py-2">
              {/* <WhatsappShareButton title={`${atividade.area} - "${atividade.nome}"`} separator=' Acesse para saber mais: ' url={complete_address}> */}
              <WhatsappShareButton title={`Confira mais sobre a atividade "${atividade.nome}" do CCBNB-${agenda.ccbnb.toUpperCase()}`} url={complete_address}>
                
                {/* <small>Compartilhar</small> <WhatsappIcon size={32} round={true} />  */}
                Enviar pelo <WhatsappIcon size={32} round={true} /> 
                  
              </WhatsappShareButton>
            </div>
            
          </div>

          
  
        </div>
      );
    }
    
    
  }
}

// export default Atividade;
export default withRouter(Atividade);