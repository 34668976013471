export const SIGN_UP = '/singup';
export const SIGN_IN = '/singin';
export const LANDING = '/';
// export const LANDING2 = '/landing';

export const LANDING2 = '/';

export const SELECT = '/select';
export const AGENDAS = '/agendas';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const AGENDA = '/agenda/:agendaId';
export const CCBNB = '/ccbnb/:ccbnb'
export const ATIVIDADE = '/atividade';
export const ATIVIDADE2 = '/atividade/:ativId';
export const ATIVIDADE3 = '/agenda/:agendaId/atividade/:atividadeId';
export const AGENDA2 = '/agenda';
export const FAVS = '/favs';
export const RESET_PASSWORD = '/resetpassword';
export const ENTRAR = '/entrar'
export const PROFILE = '/profile'