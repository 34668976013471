import React, { Component } from 'react'
import { Button, FormGroup, Label, Input } from "reactstrap";

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value
});

const INICIAL_STATE = {
  email: "",
  error: null
};

export default class ResetPassword extends Component {
  
  constructor(props, context) {
    super(props, context);
    this.state = { ...INICIAL_STATE };
  }

  render() {

    const { email, error } = this.state;

    return (
      <div className='col-md-8 offset-md-2 pt-3'>
        <FormGroup>
          <Label for="exampleEmail">Email</Label>
          <Input
            onChange={event => this.setState(byPropKey('email', event.target.value))}
            innerRef={email}
            type="email"
            name="email"
            placeholder="Insira seu email"
          />
        </FormGroup>
        { error && <p>{error.message}</p> }
        <Button>Enviar</Button>
        
      </div>
    )
  }
}
