
const nameFavLocalStore = (agendaId) => `agendaFavs/${agendaId}`


const getLocalFavs = (agendaId) => {
  const storeNameFavsAgenda = nameFavLocalStore(agendaId)
  return JSON.parse(localStorage.getItem(storeNameFavsAgenda));
}

const setLocalFavs = (agendaId, favs) => {
  const storeNameFavsAgenda = nameFavLocalStore(agendaId)
  localStorage.setItem(storeNameFavsAgenda, JSON.stringify(favs));
}


export {
  getLocalFavs, 
  setLocalFavs, 
}