export const favsConstants = {

  FAV_FETCHING: 'FAV_FETCHING',
  FAV_SET: 'FAV_SET',
  FAV_RESET: 'FAV_RESET',

  FAV_LIKE: 'FAV_LIKE',
  FAV_DISLIKE: 'FAV_DISLIKE', 

}
