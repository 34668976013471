import React from 'react'

const AccountPage = () => {
  return (
    <div className='container'>
      <div className='col-md-8 offset-md-2'>
        <h2 className='text-center my-3'>Minha Conta</h2>
      </div>
    </div>
  )
}

export default AccountPage
