import React, { Component } from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from 'react-router-dom';

import './AgendaCover.css'

export default function AgendaCover({agenda}) {
  return (
    <div className='py-2'>
      <Link className="agendaLink2"
        to={{
          pathname: `/agenda/${agenda.id}`,
          state: {
            agenda: agenda
          }
        }} >
        <div className="card" >
          <div className="card-img-top agendaCoverStyle">
            <LazyLoadImage 
              src={agenda.capa}
              width="100%"
              height="100%"
              className="agendaCoverStyle"
              // effect="blur"
              placeholderSrc={require('../../assets/images/capaplaceholder.jpg')}
              // placeholderSrc={<img src='../../assets/images/capaplaceholder.jpg' width="100%" />}
              // placeholder={<div>LOADING...</div>}
              alt={`Capa da agenda ${agenda.nome}`}
            />
          </div>
          
          <div className="card-body">
            <h5 className="card-text">CCBNB-{agenda.ccbnb.toUpperCase()}</h5>
            <div className=" justify-content-center">{agenda.nome.toUpperCase()}</div>
          </div>
        </div>
      </Link>
    </div>
  )
}