import React, { useEffect, useRef } from 'react';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faMapMarkerAlt, faClock, faLink } from '@fortawesome/free-solid-svg-icons'

import mainColors from '../../constants/color'

import ReactLoading from 'react-loading';

import './AtividadeSimples.css'

import * as S from './styles'



import {
  WhatsappShareButton,
  WhatsappIcon
} from "react-share";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import RoundButton from '../RoundButton/RoundButton';


const diaLocal = (evento, index) => {

  let data = new Date(evento.dateTime.seconds * 1000);
  let dataString = 'Dia ' + moment(data).format('DD/MM/YYYY[,] dddd');
  let horas = moment(data).format('HH[h]');
  let minutos = moment(data).format('mm');

  const horaString = minutos !== '00' ? horas + minutos : horas;
  
  return (
    <div key={index} className='pt-1 py-2'>
      <div><FontAwesomeIcon color={mainColors.orangeBNB} icon={faCalendar} fixedWidth/> {dataString}</div>
      <div><FontAwesomeIcon color={mainColors.orangeBNB} icon={faClock} fixedWidth/> {horaString}</div>
      <div><FontAwesomeIcon color={mainColors.orangeBNB} icon={faMapMarkerAlt} fixedWidth/> {evento.local}</div>
    </div>
  )

}


const tituloAtividade = (atividade) => {
  if (atividade.nome === atividade.grupoArtista) 
    return (
      <div className="activity_title">{atividade.nome} ({atividade.origemArtista})</div>
    )
  return (
    <div>
      <div className="activity_title">{atividade.nome}</div>
      <div className="activity_text">{atividade.grupoArtista} ({atividade.origemArtista})</div>
    </div>
  )
}

const smartDuration = (minutes) => {
  
  if (minutes == 60)
      return "1h"

  if (minutes > 60) {
      var h = Math.floor(minutes / 60)
      var m = minutes % 60

      if (m == 0)
          return h + 'h';

      return h + 'h' + m +'min';
  }


  return minutes + 'min';
}



export default function AgendaSimples (props) {
  // console.log("🚀 ~ file: AtividadeSimples.js:65 ~ props", props)

  const {agenda, atividade, isFav, fetching, error } =  props
  console.log("🚀 ~ AgendaSimples ~ atividade:", atividade)
  console.log("🚀 ~ AgendaSimples ~ agenda:", agenda)

  const ref = useRef(null)

  const toTop = () => {
    
    window.scroll(0, 0)

  }

  useEffect(() => {

    ref.current?.scrollTo(0,0)

    setTimeout(() => {
      ref.current?.scrollTo(0,0)
    }, 200);
  }, [atividade])

  
  if (error != null) {
    return (
      <div style={{ minHeight: '80vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
        {error}
      </div>        
    )
  }


  

  if ( atividade ) {
    // console.log("🚀 ~ file: AtividadeSimples.js:139 ~ atividade:", atividade)

    const titulo = tituloAtividade(atividade)

    let complete_address = `https://agendaccbnb.com.br/agenda/${atividade.idAgenda}/atividade/${atividade.idAtividade}`
    // console.log("🚀 ~ complete_address:", complete_address)
    
    return (
      
        
        <S.Container>

          
          <div className='py-1'>
            {titulo}
          </div>
          
          { 
            !atividade.youtube && atividade.imagem && 
            <>
              <S.ActivityLazyLoadImage
                src={atividade.imagem ? atividade.imagem : require('../../assets/images/capaplaceholder.jpg')}                
                placeholderSrc={require('../../assets/images/capaplaceholder.jpg')}
                alt={`imagem divulgação da atividade ${atividade.nome}`}
              />
            </>
            
          } 

          { 
            atividade.youtube && 
            <div className='text-center'>
              <iframe width="100%" height="300vh" src={`https://www.youtube.com/embed/${atividade.youtube}`} 
                title={atividade.youtube}
              frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen={true}></iframe>
            </div>
            
          }

          
          <div className="activity_text"><small>{atividade.area}</small></div>
          <div className="activity_text"><b>{atividade.programa}</b></div>
          
          { atividade.eventos.map((evento, index) => diaLocal(evento, index)) }
          
          <div className="activity_text">
            {atividade.release}
          </div>  
          <div className="activity_text">Duração: {smartDuration(atividade.duracao)}</div>
          <div className="activity_text">Classificação: {atividade.classificacao}</div>  

          <div className="pt-2 pb-4">
            
            {/* <WhatsappShareButton title={`Confira mais sobre a atividade "${atividade.nome}" do CCBNB`} url={complete_address}>
              
              Enviar pelo <WhatsappIcon size={32} round={true} /> 
                
            </WhatsappShareButton> */}

            <div style={{display: 'flex'}}>
                
              <WhatsappShareButton title={`Confira mais sobre a atividade "${atividade.nome}" do CCBNB`} url={complete_address} class="pr-2">
                
                <WhatsappIcon size={32} round={true} /> Enviar pelo WhatsApp
                  
              </WhatsappShareButton>
              
              <div class="pl-2">
                <RoundButton $primary url={complete_address} text="Copiar Link">
                  <FontAwesomeIcon icon={faLink} />
                </RoundButton>  
              </div>

            </div>


          </div>
          
        </S.Container>

        

      
    );
  }

  return (
    <div style={{ minHeight: '80vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
      {/* <ReactLoading type={'spin'} color={mainColors.orangeBNB} height={'20%'} width={'20%'} />   */}
    </div>        
  )
    
    
}
