import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from "react-redux";

import * as routes from "../constants/routes";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

import { firebasedb } from '../firebase';

import { applyAddFav, applyRemoveFav } from '../actions/favsActions'


class FavButton extends Component {

  constructor(props) {
    super(props);
    const { isFav } = props;
    this.state = { isFav }; 
  }

  componentDidMount = () => {
    
    const { _curtiu } = this.props;
    const { isFav } = this.state;
		
    const realFav = ( isFav )  ? isFav : _curtiu
		
    this.setState({
      ...this.state, 
      curtiu: realFav
    })
  }
  
  onClickDescurtir = () => {
    
		const { user, atividade, agenda, onRemoveFav } = this.props;

    firebasedb.doRemoveFav(user.uid, agenda.id, atividade)
    .then( (err) => {
      if (!err) {
        console.log("DESCURTIU ");
        this.setState({
          ...this.state, 
          curtiu: !this.state.curtiu,
        });
        onRemoveFav(agenda.id, atividade.atividadeId);         
      }

    });  
  }

  onClickCurtir = () => {
    
    const { user, atividade, agenda, onAddFav } = this.props;		
		
    firebasedb.doCreateFav(user.uid, agenda, atividade)
    .then( () => {
      
      this.setState({
        ...this.state, 
        curtiu: !this.state.curtiu,
      });
      console.log("CURTIU");
      onAddFav(agenda.id, atividade.atividadeId);

    });  
  }
  
  saveToRedirect = () => {
    console.log('AHAHAHAHA')
    const link = this.props.location.pathname
    localStorage.setItem("redirecdtLink", link);
    // localStorage.setItem("redirecdtAtividade", JSON.stringify(this.props.atividade));
  }

  render() {

    const { agenda, atividade, user, _curtiu } = this.props;
    const { curtiu } = this.state;

    if ( !user ) {

      return (
        <div className='alert alert-info'>
          <span>Se deseja curtir e receber notificações sobre essa atividade, </span>
          <b>
          {/* <Link 
            to={{
              pathname: routes.LANDING2,
              state: {
                atividade,
                nextPathName: this.props.location.pathname,
                redirectoToActivity: true
              }
            }} 
          >
          {`faça seu login ou cadastro. `}
          </Link> */}

          <Link to={routes.ENTRAR} onClick={this.saveToRedirect} >
            {`faça seu login ou cadastro. `}
          </Link>

          {/* <Link 
            to={{
              pathname: routes.ENTRAR,
              state: {
                atividade,
                nextPathName: this.props.location.pathname,
                redirectoToActivity: true
              }
            }} 
          >
          {`faça seu login ou cadastro. `}
          </Link>
          */}
          </b> 

        </div>
        

      )
    }

    if ( navigator.onLine && ( _curtiu || curtiu) ) {
    // if ( navigator.onLine && ( curtiu ) ) {
      return (
        // cor do ícone icone combinando com o outline do botão 
        
        // <button className='btn btn-sm btn-outline-danger' onClick={() => this.onClickDescurtir(this.props)}>
        //   <FontAwesomeIcon icon={faHeart} color="dark-red"  fixedWidth /> {' '} Favorito
        // </button>     

        // cores de icone combinando com botao - usando className no FontAwesome
        // <button className='btn btn-sm btn-outline-orange-bnb' onClick={() => this.onClickDescurtir(this.props)}>
        //   <FontAwesomeIcon icon={faHeart} className="btn-orange-outline"  fixedWidth /> {' '} Favorito
        // </button>     

        <button className='btn btn-sm btn-primary' onClick={() => this.onClickDescurtir(this.props)}>
          <FontAwesomeIcon icon={faHeart}  fixedWidth /> {' '} Favorito
        </button>     
      )
  
    } else if ( navigator.onLine ) {
      return (
        <button className='btn btn-sm btn-outline-info' onClick={() => this.onClickCurtir(user.uid, agenda, atividade)}>
            <FontAwesomeIcon icon={faHeart} color="dark-grey" fixedWidth /> {' '} Adicionar a Favoritos
        </button>     
      )
    } 
    
    return(<div></div>);
    
  }
}


// const getCurtiu = (favs, atividade) => {  
//   return favs && favs.atividades && Object.keys(favs.atividades).includes(atividade.atividadeId) ? true : false;
// }

const getCurtiu = (favs, agendaId, atividadeId) => {  
  return favs &&  favs[agendaId] && favs[agendaId].includes(atividadeId) ? true : false;
}

const mapStateToProps = (state, props) => ({
  user: state.sessionState.authUser,
  favs: state.favsState.favs,
  // _curtiu: getCurtiu(state.favsState.favs, props.atividade)
  _curtiu: getCurtiu(state.favsState.favs, props.agenda.id, props.atividade.atividadeId)
});

const mapDispatchToProps = (dispatch, props) => ({
  // onRemoveFav: (atividade) => dispatch({ type: 'FAV_REMOVE', atividade }),
  // onAddFav: (atividade) => dispatch({ type: 'FAV_ADD', atividade }),
  // onRemoveFav: () => dispatch({ type: 'FAV_REMOVE', action: { atividadeId: props.atividade.atividadeId, agendaId: props.agenda.id } }),
  // onAddFav: () => dispatch({ type: 'FAV_ADD', action: { atividadeId: props.atividade.atividadeId, agendaId: props.agenda.id } }),

  onRemoveFav: (agendaId, atividadeId) => dispatch(applyRemoveFav(agendaId, atividadeId)),
  onAddFav: (agendaId, atividadeId) => dispatch(applyAddFav(agendaId, atividadeId)),

  
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FavButton));
