import React from 'react'
import { history } from '../../constants/history'
import * as routes from "../../constants/routes";
import ReactLoading from 'react-loading';
import mainColors from '../../constants/color';

import './Landing.css'

export const Landing = () => {

  const ccbnb = localStorage.getItem('ccbnb')
  if (!!ccbnb) {
    
    history.push(`/ccbnb/${ccbnb}`)
    // console.log('aquii', ccbnb)  
    
    
  } else {
    history.push(routes.SELECT)
    console.log('CHEGANDO AQUI E NÃO TEMOS NENHUM CCBNB SELECIONADO')
  }

  return (
    <div className="pt-5 w-100">
      <ReactLoading type={'spin'} color={mainColors.orangeBNB} height={'20%'} width={'20%'} />  
    </div>
  )

  
}

