import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import App from './components/App';
import store from './store';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./scss/main.scss";

// import { BrowserRouter as Router, Route } from 'react-router-dom'

import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>, document.getElementById('root') );


registerServiceWorker();

if (module.hot) {
    module.hot.accept();
}
