import ReactGA from 'react-ga'
import { history } from '../constants/history'

const gaIdDev = 'UA-131469565-2'
const gaIdProd = 'UA-131469565-1'

const config = process.env.NODE_ENV === 'production'
    ? gaIdProd
    : gaIdDev;

export function initGa () {
  console.log('==> initializing ga...')
  ReactGA.initialize(config)
  history.listen(location => ReactGA.pageview(location.pathname));
}


