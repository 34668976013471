import { getActivities, getLastTwoAgendasByCCBNB } from '../firebase/agendafirestore'
import { ativsByDay } from '../helpers/activitiesHelpers'
import moment from 'moment';

const maxTotalActivitiesToShow = 6;

const extractAtivsByDayOnAgenda = async (idAgenda) => {
  const lastAgendaActivities = await getActivities(idAgenda).get();
  const ativs =  lastAgendaActivities.docs.map(function (documentSnapshot) {
    return { ...documentSnapshot.data(), atividadeId: documentSnapshot.id } 
  })
  const resultByDay = ativsByDay(ativs)
  return resultByDay
}

const getNextEvents = (ativsByDayLastAgenda, mesAgenda) => {

  const todayDate = moment(); 
  const todayString = moment(todayDate).format('DD/MM/YYYY')

  // const todayDate = new Date(); 
  // const todayString = todayDate.format('DD/MM/YYYY')
  // console.log("🚀 ~ file: agendaService.js:69 ~ getNextEvents ~ todayString", todayString)
  

  // const endOfMonth = moment(todayDate).endOf('month'); 
  // const endOfMonth = moment(mesAgenda.seconds).endOf('month'); 
  const endOfMonth = moment.unix(mesAgenda.seconds).endOf('month'); 

  let collectedEvents = [];
  let collectedActivities = []
  let countDays = 0;
  let checkNumAct = false
  let checkEndOfMonth = false
  
  do {

    // const day = moment(todayDate).add(countDays, 'days');
    const day = moment.unix(mesAgenda.seconds).add(countDays, 'days');
    const dayString = moment(day).format('DD/MM/YYYY')
    
    if (Object.keys(ativsByDayLastAgenda).includes(dayString)){

      ativsByDayLastAgenda[dayString].forEach(element => {      
        let ativ = element.atividade
        // 1) Todas as atividades que vão acontece do dia atual (hoje) vao resultar em evento coletado que vai ser um slide
        // 2) As atividades que vão contecer nos dias seguintes só são mostradas em sua primeira ocorrência, isto é, no primeiro evento da atividade;
        // Por exemplo: 
        // Oficina X, acontece amanhã e depois de amanhã -> só coleta um evento (o primeiro, i.e., a sessão q ocorre amanhã) para virar um slide
        if (dayString === todayString && moment(element.evento.dateTime).isAfter(todayDate))  {
          collectedEvents.push(element)
          collectedActivities.push(element.atividade)
        } else 
        if (!collectedActivities.includes(ativ) && moment(element.evento.dateTime).isAfter(todayDate) ){
          collectedEvents.push(element);
          collectedActivities.push(ativ)
        }
      });
    }

    countDays++;
    checkNumAct = collectedEvents.length < maxTotalActivitiesToShow 
    checkEndOfMonth = moment(day).isBefore(endOfMonth)

  } while ( checkNumAct &&  checkEndOfMonth )

  return collectedEvents;

}

const getAgendaToday = async (ccbnb) => {
  
  if (!!ccbnb) {

    let resultEvents = []
    const lastAgendaSnap = await getLastTwoAgendasByCCBNB(ccbnb).get()
    
    if (lastAgendaSnap.empty){
      return []
    }

    //check from which agenda to fetch activities

    const lastAgendas = lastAgendaSnap.docs.map(function (documentSnapshot) {
      return documentSnapshot.data() 
    })

    lastAgendas.reverse() // starts by the older agenda

    let countAgenda = 0;

    do {

      // if (sameMonth(lastAgendas[countAgenda].dateTime, today)) {

      // }

      const ativsByDayLastAgenda = await extractAtivsByDayOnAgenda(lastAgendas[countAgenda].id)
      const resultA = getNextEvents(ativsByDayLastAgenda, lastAgendas[countAgenda].mes)
      resultEvents.push(...resultA)

      countAgenda++
    } while (countAgenda < lastAgendas.length && resultEvents.length < maxTotalActivitiesToShow )

    return resultEvents

  }

  return []
}

const trackFinishedEvents = (nexts) => {
  
  const todayDate = moment();

  nexts = nexts.filter(act => moment(act.evento.dateTime).add(act.atividade.duracao, 'minutes').isAfter(todayDate) )
  
  return nexts.length == 0 ? null : nexts
  
}


export {

  getAgendaToday, trackFinishedEvents

}