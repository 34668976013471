import React from "react";
import { connect } from 'react-redux';
import { 
  firebase, 
  // firebasedb 
} from '../firebase';
import { askForPermissionToReceiveNotifications } from '../firebase/messaging'
import { loginSuccess, logout } from '../actions/sessionActions'


let listenerAuth;

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    
    componentDidMount = () => {
      const { 
        // onSetAuthUser, 
        onSetLogout 
      } = this.props;

      

      listenerAuth = firebase.auth.onAuthStateChanged(authUser => {
			  
        if (authUser) {
          // console.log('pegando o usuário...')
          // firebasedb.onceGetUser(authUser.uid)
          //   .then( 
          //     (snapshot) => {
          //       onSetAuthUser( {...authUser, ...snapshot.val()} )
          //     }
          //   );

          askForPermissionToReceiveNotifications(authUser.uid);

        } else {
          // onSetAuthUser(null);
          onSetLogout()
        }
      });
      
    };

    componentWillMount = () => {
      if (listenerAuth )
        listenerAuth();
    }

    render() {
      return (
          <Component />
      );
    }

  }

  const mapDispatchToProps = (dispatch) => ({
    // onSetAuthUser: (authUser) => dispatch({ type: 'AUTH_USER_SET', authUser }),
    onSetAuthUser: (authUser) => dispatch(loginSuccess(authUser)),
    onSetLogout: () => dispatch(logout()),
  });

  const mapStateToProps = state => ({
    authUser: state.sessionState.authUser,
  });

  // return connect(null, mapDispatchToProps)(WithAuthentication);
  return connect(mapStateToProps, mapDispatchToProps)(WithAuthentication);
};

export default withAuthentication;
