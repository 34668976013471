import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { firebasedb } from '../firebase';

import { Button, Form, FormGroup, Label, Input, FormFeedback } from "reactstrap";
import { redirectLogin, saveUser } from '../actions/sessionActions'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faHourglass, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const INICIAL_STATE = {
  submiting: false, 
  saved: false, 
  username: "",
  ccbnb: "-",
  error: null, 
  validForm: null,
  validations: {
    username: { pass: null },
    ccbnb: { pass: null },
  }
};



const Profile =  ( props ) => {
  
  return (
    <div className='container'>
      <div className='col-md-8 offset-md-2'>
        <h2 className='text-center my-3'>Suas informações</h2>  
        <SignUpForm {...props} />
      </div>
    </div>
  );
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value
});

class SignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INICIAL_STATE };
  }

  componentDidMount = async () => {

    const { authUser } = this.props;
    
    
    if ( authUser ) {

      authUser.username = authUser.username || ''
      authUser.ccbnb = authUser.ccbnb || ''

      await this.setState({
        ...this.state, 
        username: authUser.username || '',
        ccbnb: authUser.ccbnb || ''
      })
      
    }

    this.validateForm();

  }

  validateName = () => {
    const { username } = this.state;
    return !(username && username !== "");
  }

  validateForm = () => {
    const { username, ccbnb, } = this.state
    const validations = { }

    validations.username = !(username && username !== "") ? { invalid: true } : { invalid: false, message: 'Informe seu nome' }
    validations.ccbnb = !(ccbnb && (['SOUSA', 'FORTALEZA', 'CARIRI'].includes(ccbnb))) ? { invalid: true, message: 'Escolha um CCBNB de sua preferência' } : { invalid: false }
    
    let result= {};
    
    result = { invalid: false }
    
    validations.password = result;
		
    const validForm = 
      !validations.username.invalid &&
      !validations.ccbnb.invalid

    this.setState({
      ...this.state, 
      validForm,
      validations
    });
  }
  
  

  onSubmit = async (event) => {

    this.setState({...this.state, submiting: true})  

    setTimeout(() => {
      this.setState({...this.state, submiting: true})  
    }, 200);

    const { authUser } = this.props;
    
    event.preventDefault();
    
    await this.validateForm();

    if (this.state.validForm) {
      console.log('VALID');

      // const { email, username, ccbnb  } = this.state;
      const { username, ccbnb  } = this.state;
      
      

      firebasedb.doCreateUser(authUser.uid, username, ccbnb)
      .then( () => {
        this.props.onSalveUser({ uid: authUser.uid, username, ccbnb})
        setTimeout(() => {
          this.setState({...this.state, submiting: false, saved: true })  
        }, 500);
        
      })
      .catch(() => {
        console.log('asquiiiasdfjsdkfd')
      })
        
    } else {

      setTimeout(() => {
        this.setState({...this.state, submiting: false, saved: false })  
      }, 500);
      
    }
    
  };

  render() {
    const { username, ccbnb, submiting, saved, validForm } = this.state
    
    return (
      <div className="m-3 pb-3">
        <Form onSubmitCapture={this.onSubmit}>
          <FormGroup>
            <Label for="username">Nome Completo</Label>
            <Input
              value={username}
              // innerRef={username}
              type="text"
              placeholder="Nome Completo"
              onChange={event =>
                this.setState(byPropKey("username", event.target.value))
              }
              // invalid={this.validateName()}
              invalid={this.state.validations.username.invalid}
              
            />
          </FormGroup>

					
          <FormGroup>
            <Label for="exampleEmail">Interessado em seguir as agendas do CCBNB:</Label>
            <Input 
              value={ ccbnb }
              type="select" 
              name="ccbnb" 
              onChange={event => this.setState(byPropKey("ccbnb", event.target.value))}
              invalid={this.state.validations.ccbnb.invalid}
            >
              <option value="-">--- Escolha um CCBNB ---</option>
              <option value="CARIRI">CARIRI</option>
              <option value="FORTALEZA">FORTALEZA</option>
              <option value="SOUSA">SOUSA</option>
            </Input>

            { this.state.validations.ccbnb && this.state.validations.ccbnb.invalid && <FormFeedback >{this.state.validations.ccbnb.message}</FormFeedback> } 
          </FormGroup>

          <div className="d-flex align-items-center flex-row bd-highlight mb-3">
            <Button type="submit" onClick={() => this.onSubmit}>
              Salvar
            </Button>
            { saved && !submiting && <FontAwesomeIcon className="mx-1" icon={faCheckCircle} fixedWidth color="green" />  }
            { submiting && <FontAwesomeIcon className="mx-1" icon={faHourglass} fixedWidth color="orange" />  } 
            { !submiting && !validForm && <FontAwesomeIcon className="mx-1" icon={faExclamationTriangle} fixedWidth color="red" /> }
          </div>
          

          {/* {error && <p> {error.message} </p>} */}
          
          
        </Form>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
});

const mapDispatchToProps = (dispatch) => ({
  onRedirectLogin: (user) => dispatch( redirectLogin(user) ),
  // onSalveUser: (user) => dispatch( loginSuccess(user) ),
  onSalveUser: (user) => dispatch( saveUser(user) ),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));

