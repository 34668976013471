import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';


export const ContainerNavBrand = styled(Link)`
  /* color:  */
  height: 100%;
  content: none;
  @media (max-width: 400px) {
    content: none;
    /* font-size: 0.3rem; */
    /* background-color: red; */
    
  }

`

