import styled, { css } from 'styled-components';


export const ContainerNavPath = styled.div`
  /* color:  */
  /* height: 100%; */

  /* @media (max-width: 768px) {
    content: none;
  } */

`

