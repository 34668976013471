import React, { Component } from "react";

import { Link, withRouter } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding } from '@fortawesome/free-solid-svg-icons'

import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

import { connect } from "react-redux";
import * as routes from "../../constants/routes";
import SignOutButton from "../SignOut";

import mainColors from '../../constants/color'
import NavbarPath from "../NavbarPath/NavbarPath";

import * as S from './styles'

class Navigation extends Component {

	constructor(props) {
		// console.log("🚀 ~ file: Navigation.js:27 ~ Navigation ~ constructor ~ props", props.location)
		super(props);
		this.toggle = this.toggle.bind(this);
	}

	componentDidMount() {
		
		this.setState({
			isOpen: false
		});
	}

	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}


	
  render() {
		
		const { authUser, location } = this.props;
		// console.log("🚀 ~ file: Navigation.js:52 ~ Navigation ~ render ~ this.props:", this.props)
		// console.log("🚀 ~ file: Navigation.js:50 ~ Navigation ~ render ~ localtion", location)
		

		// const linkHome = authUser && authUser.ccbnb ? routes.HOME : routes.LANDING;
		// const linkHome = authUser && authUser.ccbnb ? `/ccbnb/${authUser.ccbnb.toLowerCase()}` : routes.LANDING;
		const linkHome = routes.LANDING;

		const navWithoutUser = (
			<Nav className="ml-auto" navbar>
				{/* <NavItem>
					<Link to={routes.LANDING2} className='nav-link'>Login</Link>
				</NavItem>
				<NavItem>
					<Link to={routes.SIGN_UP} className='nav-link' >Cadastre-se</Link>
				</NavItem> */}

				<NavItem>
					<Link to={routes.SELECT} className='nav-link'> <FontAwesomeIcon icon={faBuilding} fixedWidth/></Link>
				</NavItem>
				

				<NavItem>
					<Link to={routes.ENTRAR} className='nav-link'>Entrar</Link>
				</NavItem>
			</Nav>
		);
		
		const navUser = (authUser) => (
			<Nav className="ml-auto" navbar>
				{/* <NavItem>
					<Link to={linkHome} className='nav-link'>Home</Link>
				</NavItem> */}
				{/* <NavItem><Link to={routes.FAVS} className='nav-link'>Favoritos</Link></NavItem> */}
				
				<NavItem>
					<Link to={routes.SELECT} className='nav-link'>CCBNBs <FontAwesomeIcon icon={faBuilding} fixedWidth/></Link>
				</NavItem>
		
				<UncontrolledDropdown nav inNavbar>
					<DropdownToggle nav caret>
						{ authUser.username }
					</DropdownToggle>
					<DropdownMenu right>
						<DropdownItem>
							{/* <a href={routes.ACCOUNT}>Conta</a> */}
							{/* <Link to={routes.ACCOUNT} className='nav-link'>Conta</Link> */}
							{/* <Link to={routes.ACCOUNT} className='nav-link'>Conta</Link> */}

							<Link to={routes.PROFILE} className='nav-link'>Conta</Link>
							
						</DropdownItem>
						<DropdownItem divider />
						<DropdownItem>
							<SignOutButton />
						</DropdownItem>
					</DropdownMenu>
				</UncontrolledDropdown>
			</Nav>
		);		

    return (
			<Navbar color="light" light expand="md" fixed="top">
				
				{/* <div className="d-flex flex-row"> */}
					<S.ContainerNavBrand to={linkHome} className='navbar-brand' style={{ color: mainColors.redBNB}}>
						<img style={{height: 25}} src={require('../../assets/images/logoccbnb2.png')} alt="logo ccbnb" /> 
					</S.ContainerNavBrand>
					{/* <div className="navbar-brand"> */}
						<NavbarPath/>
					{/* </div> */}
					
				{/* </div> */}
				
				{/* <Link to={linkHome} className='navbar-brand' style={{ color: mainColors.redBNB}}><strong>Agenda CCBNB</strong></Link> */}
				{/* <Link to={linkHome} className='navbar-brand' style={{ color: mainColors.redBNB}}>Agenda CCBNB</Link> */}
				{/* <NavbarBrand href={routes.LANDING}>Agenda CCBNB</NavbarBrand> */}
				<NavbarToggler onClick={this.toggle} />
				<Collapse isOpen={this.state && this.state.isOpen} navbar>
					{ authUser ? navUser(authUser) : navWithoutUser }
				</Collapse>
			</Navbar>
		);

  }
}



const mapStateToProps = state => ({
	authUser: state.sessionState.authUser,
	// authUser: state.userState.user,
});

export default connect(mapStateToProps)(Navigation);
