import { LazyLoadImage } from 'react-lazy-load-image-component';
import styled, { css } from 'styled-components';
import mainColors from '../../constants/color'
import { Link } from 'react-router-dom';


export const SimpleRoudedButtonContainer = styled.button`
  text-decoration: none;
  background-color: transparent; 
  border: 0
`;

export const SimpleRoudedButton = styled.button`
  background-color: ${({props}) => (props.$primary ? mainColors.redBNB : "black")}; 
  color: ${({props}) => (props.$primary ? "white" : "white")};
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 50%;

`;

