// import React, { useState } from 'react';
// import { connect } from "react-redux";
// import { Link, withRouter } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
// import * as routes from "../../constants/routes";

// const breadCCBNB = (ccbnb) => {
//   return (
//     <div key='bread'>{' '}<FontAwesomeIcon icon={faChevronRight}  fixedWidth /> {ccbnb.toUpperCase()}</div>
//     // <div key='bread'>{ccbnb.toUpperCase()}</div>
//   )
// }

// const breadAgenda = (agenda, ccbnb) => {

//   return (
//     <div key='bread'>
//       <Link to={{
//         pathname: `/ccbnb/${agenda}`,
//         state: {
//           ccbnb: ccbnb,
//           index: ccbnb,
//         }
//       }}>
//       {' '}<FontAwesomeIcon icon={faChevronRight}  fixedWidth /> {agenda.ccbnb.toUpperCase()} 
//       </Link>
//       {' '}<FontAwesomeIcon icon={faChevronRight}  fixedWidth /> {agenda.nome}
//     </div>
//   )
// }

// const INITIAL_STATE = {
//   locate: "home"
// }

// function NavbarPath(props) {

//   const {authUser, history, match} = props

//   const [navigationState, setNavigationState] = useState(INITIAL_STATE)
  
//   history.listen((location, action) => {

//     // location is an object like window.location

    
//     if (location.pathname == '/') {
//       setNavigationState(INITIAL_STATE)
//     } else  if (location.pathname == '/home') {

//       if (authUser?.ccbnb)
//         setNavigationState({ locate: 'ccbnb' , ccbnb: authUser.ccbnb})
//       else 
//         setNavigationState({ locate: 'ccbnb' , ccbnb: location.state.ccbnb})
      
//     } else {



//       if (location && location.state){
      
//         const { ccbnb, agenda } = location.state
//         console.log("🚀 ~ file: NavbarPath.js:47 ~ history.listen ~ location.state", location.state)
        
    
//         if (agenda)  {
          
//           setNavigationState({ locate: 'agenda' , agenda: agenda})
//         } else if (ccbnb) {
          
//           setNavigationState({ locate: 'ccbnb' , ccbnb: ccbnb})
//         } else {
          
//           setNavigationState(INITIAL_STATE)
//         }
//       }
//     }

//     if (location.pathname.startsWith("/ccbnb")) {
//       console.log("HELLO HELO ")
//     }

    
    
//   });
  
  
//   if (navigationState.locate === 'agenda')  {
//     return breadAgenda(navigationState.agenda, navigation.agenda.ccbnb)
//   } else if (navigationState.locate === 'ccbnb') {
//     return breadCCBNB(navigationState.ccbnb)
//   }
//   return (
//     <div></div>
//   );
// }


// const mapStateToProps = state => ({
//   authUser: state.sessionState.authUser
// });


// export default connect(mapStateToProps)(withRouter(NavbarPath));



import React from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faHome } from '@fortawesome/free-solid-svg-icons';
import * as routes from "../../constants/routes";

import * as S from './styles'

const homeIconWithLink = () => (
  <Link to={routes.LANDING}>
    <FontAwesomeIcon icon={faHome}  fixedWidth />
  </Link>)


const breadCCBNB = (ccbnb) => {
  return (
    
    <S.ContainerNavPath key='bread'>
      {/* {homeIconWithLink()}
      {' '} */}
      <FontAwesomeIcon icon={faChevronRight}  fixedWidth /> 
      {ccbnb.toUpperCase()}
    </S.ContainerNavPath>
  )
}

const breadAgenda = (agenda) => {
  
  
  return (
    <S.ContainerNavPath key='bread'>
      {/* {homeIconWithLink()} */}

      <Link to={{
        pathname: `/ccbnb/${agenda.ccbnb.toLowerCase()}`,
        state: {
          ccbnb: agenda.ccbnb,
          index: agenda.ccbnb,
        }
      }}>
        {/* {' '} */}
        <FontAwesomeIcon icon={faChevronRight}  fixedWidth /> {agenda.ccbnb.toUpperCase()} 
      </Link>
      <FontAwesomeIcon icon={faChevronRight}  fixedWidth /> {agenda.nome}
    </S.ContainerNavPath>
  )
}






// class NavbarPath extends Component {

  
//   render() {

//     const {breadcombCCBNB, breadcombAgenda, breadcombCompleted} = this.props
//     console.log("🚀 ~ file: NavbarPath.js:155 ~ NavbarPath ~ render ~ this.props", this.props)

//     if (breadcombCompleted && breadcombCCBNB)  
//         return breadCCBNB(breadcombCCBNB)
      
//       if (breadcombCompleted && breadcombAgenda) 
//         return breadAgenda(breadcombAgenda, breadcombCCBNB)
    
//     return (
//       <div></div>
//     );
//   }
// }




const NavbarPath = ({breadcombCCBNB, agenda, breadcombAgenda, breadcombCompleted}) => {
  // console.log("🚀 ~ file: NavbarPath.js:170 ~ NavbarPath ~ breadcombCCBNB, agenda, breadcombAgenda, breadcombCompleted", breadcombCCBNB, agenda, breadcombAgenda, breadcombCompleted)
  
  if (breadcombCCBNB)  
    return breadCCBNB(breadcombCCBNB)
      
  if (agenda) 
    return breadAgenda(agenda)
  
  return (
    <div></div>
  );
}


const mapStateToProps = (state) => ({

  breadcombCCBNB: state.sessionState.breadcombCCBNB,
  breadcombAgenda: state.sessionState.breadcombAgenda,
  agenda: state.agendaState.agenda,
  breadcombCompleted: state.sessionState.breadcombCompleted
});


export default connect(mapStateToProps)(NavbarPath);

