import React from 'react'
import * as routes from "../constants/routes";
import './SelectorCcbnb.css'


import AlertToLogin from './AlertToLogin'


const SelectorCcbnb = (props) => {
  
  const selectCcbnb = (ccbnb) => {
  
    // props.history.push({
    //   pathname: routes.HOME,
    //   state: {
    //     ccbnb: ccbnb,
    //     index: ccbnb,
    //   }
    // })

    // to={{
    //   pathname: `/agenda/${agenda.id}`,
    //   state: {
    //     agenda: agenda
    //   }
    // }}

    props.history.push({
      pathname: `/ccbnb/${ccbnb}`,
      state: {
        ccbnb: ccbnb,
        index: ccbnb,
      }
    })
  
  }


  return (
    <>

      <div className="d-flex flex-column flex-wrap align-items-center align-content-center justify-content-center" style={{minHeight: "90vh", width: "100vw",}} >
        
        <div className="d-flex align-items-center m-2 container-img-ccbnb"  onClick={()=> selectCcbnb('cariri')}>
          <img className="img-ccbnb" src={require('../assets/images/fac_cariri.jpg')} alt="capa escolher ccbnb cariri" /> 
          <div className="px-2 txt-ccbnb flex-grow-1">
            Cariri
          </div>
        </div>

        <div className="d-flex align-items-center m-2 container-img-ccbnb" onClick={()=> selectCcbnb('fortaleza')}>
          <img className="img-ccbnb" src={require('../assets/images/fac_fortaleza3.jpg')} alt="capa escolher ccbnb fortaleza" /> 
          {/* <img className="img-ccbnb" src={ccbnbFortaleza} alt="capa escolher ccbnb fortaleza" />  */}
          <div className="px-2 txt-ccbnb flex-grow-1">
            Fortaleza
          </div>
        </div>

        <div className="d-flex align-items-center m-2 container-img-ccbnb" onClick={()=> selectCcbnb('sousa')}>
          <img className="img-ccbnb" src={require('../assets/images/fac_sousa.jpg')} alt="capa escolher ccbnb sousa" /> 
          <div className="px-2 txt-ccbnb flex-grow-1">
            Sousa
          </div>
        </div>

        <div className="d-flex align-items-center m-2 " >
          <AlertToLogin />
        </div>

      </div>
        
      
    </>

    



  )
}


export default SelectorCcbnb;

