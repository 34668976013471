// export * from './AgendaCalendario'

// import { AgendaCalendario } from './AgendaCalendario';
// export default AgendaCalendario;

import AgendaCalendario from './AgendaCalendario';
export default AgendaCalendario;

// export { default } from './AgendaCalendario';

// export { default } from './AgendaCalendario.js';

