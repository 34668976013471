import React, { Component,  } from 'react';
import ReactDOM from 'react-dom';
import { connect } from "react-redux";

import { 
  agendafire, 
  // firebasedb 
} from '../firebase';
import { agendaMensal, ativsByArea, ativsByDay } from './AgendaHelpers'

import { fetchAgendaFavs } from '../actions/favsActions'

import moment from 'moment';
import 'moment/locale/pt-br';

import ScrollManager from './ScrollManager';

import { TabContent, TabPane, Nav, NavItem, NavLink,  } from 'reactstrap';
import classnames from 'classnames';
import AgendaPorArea from './AgendaPorArea';
import AgendaCalendario from './AgendaCalendario/';

import mainColors from '../constants/color';
import ReactLoading from 'react-loading';

import { agendaByDay, getAgendaAnyway } from '../services/agendaService'
import FavsPageAgenda from './FavsPageAgenda';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faHeart } from '@fortawesome/free-solid-svg-icons';

import {Helmet} from "react-helmet";

import ModalActivity from './ModalActivity/ModalActivity';

import { toogleModal } from '../actions/modalActivityActions';
import AtividadeSimples from './AtividadeSimples';
import AgendaPorArea2 from './AgendaPorArea2';


const INICIAL_STATE = {
  agenda: null,
  date: new Date(), 
  loading: true,
  // activeTab: '1'
};


let daysUnsub = null;
let atividadesUnsub = null;

// class
class Agenda extends Component {

  constructor(props, context) {

    super(props, context);
    
    this.state = { ...this.INICIAL_STATE };
    
    moment().locale('pt-br');
    this.toggle = this.toggle.bind(this);

  }


  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  
  getAgendaData = (agenda) => {
    

    const { onSetAgenda } = this.props;
    let storeNameAgenda = `agendaState/${agenda.id}`;
    
    //preparando promises
    daysUnsub = agendafire.getDays(agenda.id).onSnapshot((snapshot) => {
      
      let days = snapshot.docs.map(function (documentSnapshot) {
        return documentSnapshot.data()
      });

      let ativs; 

      atividadesUnsub = agendafire.getActivities(agenda.id).onSnapshot((snapshot) => {
      
        ativs = snapshot.docs.map(function (documentSnapshot) {
          return { ...documentSnapshot.data(), atividadeId: documentSnapshot.id } 
        });


        if (ativs && ativs.length > 0) {
          let aByDay = ativsByDay(ativs);
          let _agendaMensal = agendaMensal(days,aByDay);
          let byArea = ativsByArea(ativs);
          

          console.log('Storing state');

          this.setState({
            ...this.state,
            todayInfos: this.mustShowToday(_agendaMensal),
            loading: false
          });

          let agendaData = {
            agenda: agenda,
            byDay: _agendaMensal,
            byArea: byArea,
            atividades: ativs, 
            days
          }
          
          onSetAgenda(agendaData);

          localStorage.setItem(storeNameAgenda, JSON.stringify(agendaData));
        }

        
  
      });

    });
        
  }

  mustShowToday = (agendaMensal) => {
    
    const todayString = moment(new Date()).format('DD/MM/YYYY');
    
    return agendaMensal.map( aDate => aDate.dateString ).includes(todayString)

  }

  
  componentDidMount = async () => {
    const { agendaId } = this.props.match.params;
    const { onFetchFavs } = this.props;

    // if (agenda && agenda.id !== agendaId) {
    //   console.log('Édiferente... ')
    //   this.setState({ ...this.INICIAL_STATE })
    // }

    let agenda;
    if (this.props.location.state) {
      agenda = this.props.location.state.agenda;
      // console.log(" 1​ Agenda -> componentDidMount -> agenda", agenda.id)
      // console.log('asdfasfasdfa : ', this.props.location.state);
    } 
    else {
      // const { agendaId } = this.props.match.params;
      if (!agenda || agenda === undefined) {
        console.log('FOI BUSCAR A AGENDA')
        agenda = await getAgendaAnyway(agendaId);
				// console.log("​2 Agenda -> componentDidMount -> agenda", agenda.id)
      }
    }

    this.setState({ ...INICIAL_STATE,  agenda }) ;

    // const { agenda } = this.state;
		// console.log("​3 Agenda -> componentDidMount -> agenda", agenda.id)

    const { 
      onSetAgenda, 
      authUser, 
      // onSetFavs 
    } = this.props;

    let storeNameAgenda = `agendaState/${agenda.id}`;
    // let agendaSessionStorageString = sessionStorage.getItem(storeNameAgenda);
    let agendaSessionStorageString = localStorage.getItem(storeNameAgenda);

    // getter active tab saved on sessionStorage
    let activeTab = sessionStorage.getItem(`activeTab-${agenda.id}`) || '1';
		
    activeTab = !authUser && activeTab === '3' ? '1': activeTab; 
    this.setState({
      activeTab: activeTab,
    })

    if (agendaSessionStorageString) {

      console.log('Agenda already stored');

      // let agendaSessionStorage = JSON.parse(agendaSessionStorageString);
      // let { agenda, byDay, byArea, atividades } = agendaSessionStorage;
      
      // let todayInfos = false;
      // const todayString = moment(new Date()).format('DD/MM/YYYY');
      // byDay.forEach(element => {
      //   if (element.dateString === todayString)
      //     todayInfos = true;
      // });

      // this.setState({
      //   ...this.state,
      //   agenda,
      //   todayInfos: todayInfos,
      //   loading: false
      // });

      // let agendaData = {
      //   agenda: agenda,
      //   byDay,
      //   byArea,
      //   atividades
      // }
      // onSetAgenda(agendaData);


      let agendaSessionStorage = JSON.parse(agendaSessionStorageString);
      let { byDay } = agendaSessionStorage;
      onSetAgenda(agendaSessionStorage);

      // let todayInfos = false;
      // const todayString = moment(new Date()).format('DD/MM/YYYY');
      // byDay.forEach(element => {
      //   if (element.dateString === todayString)
      //     todayInfos = true;
      // });

      const todayInfos = this.mustShowToday(byDay)
      
      this.setState({
        ...this.state,
        agenda,
        todayInfos: todayInfos,
        // todayInfos: todayInfos,
        loading: false
      });

      
    } 

    if (navigator.onLine) {

      console.log('is Online');
      this.getAgendaData(agenda);
      
    } else {
      console.log('is Offline');
    }

    //getting favs
    if ( authUser ) {

      // firebasedb.onceGetFavsByAgenda(authUser.uid, agenda.id)
      // .then( (snapshot) => {
      //   const favs = snapshot.val();
			// 	console.log("​Agenda -> componentDidMount -> favs", favs)
        
      //   onSetFavs(favs)
      // })

      onFetchFavs(authUser.uid, agenda.id);
 
    }

  }


  componentWillUnmount = () => {

    const { onResetAgenda } = this.props;

    if (this.state.agenda) {
      let agendaId = this.state.agenda.id;
      // setter
      sessionStorage.setItem(`activeTab-${agendaId}`, this.state.activeTab);
      onResetAgenda();
    }
    
    if (daysUnsub) 
      daysUnsub();
    if (atividadesUnsub)
      atividadesUnsub();

  }

  render() {

    const { todayInfos, agenda } = this.state;
    
    const { byDay, isSearching, authUser } = this.props;

    if (!agenda || agenda  === undefined) {
      
      return (
        <div style={{ minHeight: '80vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
          <ReactLoading type={'spin'} color={mainColors.orangeBNB} height={'20%'} width={'20%'} />  
        </div>          
      )
    }

    // if ( !agenda && !byDay && agenda !== undefined && byDay !== undefined ) {
    if ( !byDay && byDay !== {} ) {

      console.log('has no recovered agenda yet');
      
      if (!navigator.onLine) {
        return (
          <div className='container mx-auto'>
            <p className="whiteText">Erro! Você pode estar offline. Tente novamente mais tarde.</p>
          </div>
        )
      } else {
        return (
          <div style={{ minHeight: '80vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
            <ReactLoading type={'spin'} color={mainColors.orangeBNB} height={'20%'} width={'20%'} />  
          </div>          
        )
      }
      
    } else {
      const title = `Agenda CCBNB - ${agenda.ccbnb.toUpperCase()} - ${agenda.nome.toUpperCase()}`
      return (
        
        <div className='centered_container pb-1'>
          <Helmet>
            <title>{title}</title>
            <meta name="title" property="og:title" content={title} />
          </Helmet>
        
          {/* <Nav pills fill className="pt-3">
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { this.toggle('1'); }}
            >
              Calendário
            </NavLink>
          </NavItem>
          
          { authUser && <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '3' })}
              onClick={() => { this.toggle('3'); }}
            >
              Favoritos
            </NavLink>
          </NavItem>
          } 

          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { this.toggle('2'); }}
            >
              Por Tipo
            </NavLink>
          </NavItem>
          </Nav> */}
              
          {/* <TabContent activeTab={this.state.activeTab}> */}
            {/* <TabPane tabId="1"> */}
              <ScrollManager scrollKey={`agenda/cal/${agenda.id}`} />
              <div className='mt-3'>
                
                {/* button TODAY */}
                { todayInfos && <div className="text-center ">
                  <button className='btn btn-outline-red-bnb' onClick={() => {
                      const myDomNode = ReactDOM.findDOMNode(this).getElementsByClassName('todayClass');
                      const x = myDomNode[0].offsetTop
                      window.scroll(0, x - 70)
                      
                    }}>
                    Ir para HOJE
                  </button>
                </div>
                }

                {/* calendário */}
                <AgendaPorArea2 />
                { !isSearching && <AgendaCalendario agenda={agenda} byDay={byDay} /> }
              </div>
              

            {/* </TabPane>
            <TabPane tabId="2">
              <ScrollManager scrollKey={`agenda/area/${agenda.id}`} />
              <AgendaPorArea />
            </TabPane> */}
            {/* <TabPane tabId="3">
              <ScrollManager scrollKey={`agenda/favs/${agenda.id}`} />
              <FavsPageAgenda  />
            </TabPane> */}
          
          {/* </TabContent> */}

          
          <ModalActivity active={this.props.openModal} hideModal={() => this.props.toogle()}>
            <AtividadeSimples fetching={this.props.modalFetching} error={this.props.modalError} agenda={this.props.atividadeModal?.idAgenda} atividade={this.props.atividadeModal} isFav={false} />
          </ModalActivity>
               
        </div>
      )
    }
    
  }

}

const mapDispatchToProps = (dispatch) => ({
  onSetAgenda: (agendaData) => dispatch({ type: 'AGENDA_UPDATE', agendaData }),
  onResetAgenda: () => dispatch({ type: 'AGENDA_RESET' }),
  // onSetFavs: (favs) => dispatch({ type: 'FAVS_SET', favs }),
  onResetFavs: (agendaId) => dispatch({ type: 'FAVS_RESET', agendaId }),
  onFetchFavs: (userId, agendaId) => dispatch(fetchAgendaFavs(userId, agendaId)),

  // modal 
  toogle: () => dispatch(toogleModal())

  
});

const mapStateToProps = state => ({
  // agenda: state.agendaState.agenda,
  byArea: state.agendaState.byArea,
  byDay: state.agendaState.byDay,
  agenda: state.agendaState.agenda, 
  atividades: state.agendaState.atividades,
  authUser: state.sessionState.authUser,
  // favs: state.favsState.favs,
  // loadingFavs: state.favsState.fetching,
  
  // modal 
  atividadeModal: state.modalState.atividadeModal,
  openModal: state.modalState.openModal,
  modalFetching: state.modalState.modalFetching, 
  modalError: state.modalState.modalError,

  searchResults: state.searchState.results,
  searchText: state.searchState.searchText,
  isSearching: state.searchState.isSearching,
});


export default connect(mapStateToProps, mapDispatchToProps)(Agenda);