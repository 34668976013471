import React, { useEffect, useRef } from 'react';
import * as S from './styles'
import { toast } from 'react-toastify';

const copyToClip2 = (url) => {
  window.navigator.clipboard.writeText(url)
  toast(`Link copiado`, {hideProgressBar: true, autoClose: 3000, position: 'bottom-center'})
}

export default function RoundButton (props) {
  return (
    <S.SimpleRoudedButtonContainer onClick={() => {copyToClip2(props.url)}}>
      
      <S.SimpleRoudedButton props={props}>
      {/* <S.SimpleRoudedButton props={props} onClick={(props) => {navigator.clipboard.writeText(props.url)}}> */}
        {props.children}
      </S.SimpleRoudedButton>
      {` ` + props.text}
    </S.SimpleRoudedButtonContainer>
  )

}