import moment from 'moment';
import { agendaMensal } from '../components/AgendaHelpers';
import { onceGetAgenda, getActivity, getActivitySimple } from '../firebase/agendafirestore'
import { ativsByDay } from '../helpers/activitiesHelpers';
const getAgendaAnyway = async (agendaId) => {

  const storeNameAgenda = `agendaState/${agendaId}`;
  const agendaSessionStorageString = localStorage.getItem(storeNameAgenda);
	

  if (agendaSessionStorageString) {
    const agendaSessionStorage = JSON.parse(agendaSessionStorageString);
    const { agenda } = agendaSessionStorage;
		// console.log("​getAgendaAnyway -> agenda", agenda)
    return agenda;

  } else {
    
    // return onceGetAgenda(agendaId).then(agenda => {return agenda.data()})
    let res =  await onceGetAgenda(agendaId);
		// console.log("​getAgendaAnyway -> res", res)
    return res;
  }

}

const getAtividadeAnyway = async (agendaId, atividadeId) => {

  const storeNameAgenda = `agendaState/${agendaId}`;
  const agendaSessionStorageString = localStorage.getItem(storeNameAgenda);

  if (agendaSessionStorageString) {
    const agendaSessionStorage = JSON.parse(agendaSessionStorageString);
    const { atividades } = agendaSessionStorage;
		


    const result = atividades.filter(ativ => ativ.atividadeId === atividadeId);
		// console.log("​getAtividadeAnyway -> result", result)

    return result[0];

  } else {
    // return getActivity(agendaId, atividadeId).then(function(doc){
    //   return doc.data()
    // });

    let res =  await getActivity(agendaId, atividadeId);
		// console.log("​getAgendaAnyway -> res", res)
    return res;
  }
}

const getAtividadeAnyway2 = async (agendaId, atividadeId) => {

  const storeNameAgenda = `agendaState/${agendaId}`;
  const agendaSessionStorageString = localStorage.getItem(storeNameAgenda);

  if (agendaSessionStorageString) {
    const agendaSessionStorage = JSON.parse(agendaSessionStorageString);
    const { atividades } = agendaSessionStorage;
		


    const result = atividades.filter(ativ => ativ.atividadeId === atividadeId);
		// console.log("​getAtividadeAnyway -> result", result)

    return result[0];

  } else {
    // return getActivity(agendaId, atividadeId).then(function(doc){
    //   return doc.data()
    // });

    // console.log('Aqui')
    return getActivitySimple(agendaId, atividadeId).get()
    .then(result => {
      // console.log("🚀 ~ file: agendaService.js:81 ~ getAtividadeAnyway2 ~ result", result)
      return result.data()
    })
    .catch(err => {
      // console.log("🚀 ~ file: agendaService.js:80 ~ getAtividadeAnyway2 ~ err", err)
      return { error: "Erro ao buscar atividade."}
      // return { error: err }
    
    })
    
		
  }
    
}


const agendaByDay = (ativs, days) => {
  if (ativs && ativs.length > 0) {
    let aByDay = ativsByDay(ativs);
    return agendaMensal(days,aByDay);
  }
  return null
}

const filteredDays = (atividades, days) => {
    
  if (atividades && days && atividades.length > 0 && days.length > 0) {
    
    let result = atividades.reduce((val, element) => {        
      if (element.eventos) {
        
        
        element.eventos.forEach(e => {
          
          val = [...val, moment(e.dateTime.toDate()).format('DD/MM/YYYY')]
        })
      }

      return val;
    }, [])
    
    let daysWithActivity = [...new Set(result.sort())]; 
    // console.log("🚀 ~ file: agendaService.js:124 ~ filteredDays ~ daysWithActivity:", daysWithActivity)
    // console.log("🚀 ~ file: agendaService.js:122 ~ filteredDays ~ um exemplo:", moment(days[0].data.seconds).format('DD/MM/YYYY'))
    
    let filteredDays = days.filter(d => daysWithActivity.includes(moment(d.data.toDate()).format('DD/MM/YYYY')))
    // console.log("🚀 ~ file: agendaService.js:128 ~ filteredDays ~ filteredDays:", filteredDays)
    
    return filteredDays;
  }

  return null;

  
}

export {
  getAgendaAnyway, 
  getAtividadeAnyway,
  getAtividadeAnyway2, 
  agendaByDay, 
  filteredDays
}