import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/firestore';

// import React from 'react';
// import { toast } from 'react-toastify';
// import NotificationAgenda from '../components/NotificationAgenda';


const prodConfig = {
  apiKey: "AIzaSyDVbgEzLeCWMCmyAMsEWksHB6sT9OP7EFs",
  authDomain: "agendaccbnb.com.br",
  databaseURL: "https://agendaccbnb.firebaseio.com",
  projectId: "agendaccbnb",
  storageBucket: "agendaccbnb.appspot.com",
  messagingSenderId: "830325325993",
  appId: "1:830325325993:android:8a552ec2bb12fc4b"
};

const devConfig = {
  apiKey: "AIzaSyDVbgEzLeCWMCmyAMsEWksHB6sT9OP7EFs",
  authDomain: "agendaccbnb.com.br",
  databaseURL: "https://agendaccbnb.firebaseio.com",
  projectId: "agendaccbnb",
  storageBucket: "agendaccbnb.appspot.com",
  messagingSenderId: "830325325993",
  appId: "1:830325325993:android:8a552ec2bb12fc4b"
};

// const devConfig = {
//   apiKey: "AIzaSyDGRXTO-3UnBmatP6JpT943wudRt93T_Us",
//   authDomain: "dev-agenda.firebaseapp.com",
//   databaseURL: "https://dev-agenda.firebaseio.com",
//   projectId: "dev-agenda",
//   storageBucket: "dev-agenda.appspot.com",
//   messagingSenderId: "919822512099",
//   appId: "1:919822512099:web:d5f94a3a1a10e8b331fad6"

// };



const config = process.env.NODE_ENV === 'production'
    ? prodConfig
    : devConfig;

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

// auth
const auth = firebase.auth();
auth.languageCode = 'pt-BR';


// db
const db = firebase.database();

// messaging
let messaging = firebase.messaging; 
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging()
}
export { messaging }





// console.log('On Message');
// messaging.onMessage( function (payload) {
//   console.log('chegou ahahah ... ', payload);  
  
//   const { notification } = payload;

//   toast( <NotificationAgenda notification={notification} /> );
  
//   return payload;
// })


// messaging.onMessage( function (payload) {
//   // console.log('chegou 2... ', payload);
//   const { title, body, click_action } = payload.notification;
//   toast.success(title + " " + body);
//   return payload;
// });

// if (messaging.isSupported()) {
//   messaging.onMessage( function (payload) {
//     console.log('chegou 2... ', payload);
    
//     return payload;
//   })
// }

// firestore
const dbfire = firebase.firestore();
const settings = { timestampsInSnapshots: true, merge: true };
dbfire.settings(settings);


// exports (except messaging)
export { 
  auth, 
  db,
  dbfire,
  // messaging, 
};
