// import React from 'react';
import { withRouter } from 'react-router-dom';

// const onClickAtividade = (history, agendaId, atividadeId) => {
// // 	console.log("​onClickAtividade -> history", history)
  
//   this.history.push({
//     pathname: `agenda/${agendaId}/atividade/${atividadeId}`,
//     state: {
//       atividadeId: atividadeId, 
//       agendaId: agendaId, 
//       index: `${atividadeId}`
//     }
//   })

// }

// const onClickAgenda = (location, history, agenda) => {
// //   // console.log("​onClickAgenda -> agenda", agenda)
  
//   const { pathname } = location;
// // 	console.log("​onClickAgenda -> pathname", pathname)

//   if ( !pathname.includes('/agenda/') ) {
//     history.push({
//       pathname: `/agenda/${agenda.id}`,
//       state: {
//         agenda: agenda,
//       }
//     })
//   } else {
//     history.push({
//       pathname: `/`,
//       state: {
//         agenda: agenda,
//       }
//     })
//   }
// }

// // const NotificationAgenda = ( { history, notification, closeToast } )  => {
// const NotificationAgenda = ( { history, location, payload, closeToast } )  => {
// // 	console.log("​NotificationAgenda -> history", history)
	
//   // const { title, body } = notification;
//   // let   { type } = notification;

//   const { title, body } = payload.notification;
//   let   { type } = payload.notification;

//   if (!type || type === undefined ) {
//     // type = payload.data["gcm.notification.notification_type"]
//     // type = payload.data["notification_type"] 
//     type = payload.data["notification_type"] || payload.data["gcm.notification.notification_type"]
		
//   }
// //   console.log("​NotificationAgenda -> type", type)
  
//   if (type === 'atividade') {

//     const { agendaId, atividadeId } = payload.notification;
//     // const history = this.props.history;
    

//     return (
//       // <div className='container' onClick={() => { console.log('Clicou')}}>
//       <div className='container-fluid' onClick={() => onClickAtividade(agendaId, atividadeId)}>
//         <div>{body}</div>
//         <h5 className='text-center mb-2' style={ {color: 'black'}}>{title}</h5>  
//       </div>
//     );
//   }

//   if (type === 'agenda') {
//     let agenda = payload.data.agenda;
// // 		// console.log("​NotificationAgenda -> agenda", agenda)

//     return (
//       <div className='container' onClick={() => onClickAgenda(location, history, JSON.parse(agenda)) }>
//         <h5 className='text-center my-1' style={ {color: 'black'}}>{title}</h5>  
//         <p>{body}</p>
//       </div>
//     )
//   }

//   return (
//     <div className='container' onClick={() => { console.log('Clicou')}}>
//       <h5 className='text-center my-1' style={ {color: 'black'}}>{title}</h5>  
//       <p>{body}</p>
//     </div>
//   );
  
// }


import React, { Component } from 'react'

class NotificationAgenda extends Component {

  constructor(props) {
    super(props);

    // this.onClickAgenda.bind(this);
    // this.onClickAtividade.bind(this);
    this.onClickNotification.bind(this);
  }
  
  onClickNotification = (type)  => {
	// console.log("​NotificationAgenda -> onClickNotification -> type", type)

    const { history, location, payload} = this.props;

    if (type === 'atividade') {
      const { agendaId, atividadeId } = payload.data;
      
      history.push({
        pathname: `agenda/${agendaId}/atividade/${atividadeId}`,
        state: {
          atividadeId: atividadeId, 
          agendaId: agendaId, 
          fav: true, 
          index: `${atividadeId}`
        }
      })

    } else {
      let agenda = JSON.parse(payload.data.agenda);

      const { pathname } = location;
    
      if ( !pathname.includes('/agenda/') ) {
        history.push({
          pathname: `/agenda/${agenda.id}`,
          state: {
            agenda: agenda,
          }
        })
      } else {
        history.push({
          pathname: `/`,
          state: {
            agenda: agenda,
          }
        })
      }

    }
    


  }

  render() {
  
	  const { payload } = this.props;
    // console.log("TCL: NotificationAgenda -> render -> this.props", this.props)
		// console.log("​NotificationAgenda -> render -> payload", payload)
    // const { title, body } = payload.notification;
    const { title, body } = payload.data;
    
    // let   { type } = payload.notification;
    // if (!type || type === undefined ) {
    //   type = payload.data["gcm.notification.notification_type"]  
    // }

    let { type } = payload.data;

    if (type === 'atividade') {
      
      return (
        // <div className='container-fluid' onClick={() => this.onClickAtividade(agendaId, atividadeId)}>
        <div className='container-fluid' onClick={ () => this.onClickNotification(type)}>
          <div>{body}</div>
          <h5 className='text-center mb-2' style={ {color: 'black'}}>{title}</h5>  
        </div>
      );
    }

    
    if (type === 'agenda') {
      
      return (
        // <div className='container' onClick={() => this.onClickAgenda(location, history, JSON.parse(agenda)) }>
        <div className='container' onClick={ () => this.onClickNotification(type)}>
          <h5 className='text-center my-1' style={ {color: 'black'}}>{title}</h5>  
          <p>{body}</p>
        </div>
      )
    }

    return (
      <div className='container' onClick={() => { console.log('Clicou')}}>
        <h5 className='text-center my-1' style={ {color: 'black'}}>{title}</h5>  
        <p>{body}</p>
      </div>
    );
  }
}


export default withRouter(NotificationAgenda);