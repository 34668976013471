import React, { Component } from "react";
import { connect } from "react-redux";
import { login } from '../actions/sessionActions'
import { 
  // Button, 
  Form, FormGroup, Label, Input } from "reactstrap";

  const byPropKey = (propertyName, value) => () => ({
    [propertyName]: value
  });
  
  const INICIAL_STATE = {
    email: "",
    password: "",
    error: null
  };
  
  const errorMessages = {
    "auth/wrong-password": "Senha inválida",
    "auth/user-not-found": "Não há nenhum usuário com o email correspondente. Confirme o email ou tente criar uma nova conta."
  }


class SignInForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { ...INICIAL_STATE };
  }

  onSubmit = event => {

    event.preventDefault();

    // const { email, password } = this.state;
    // const { history } = this.props;
    
    // auth
    //   .doSignInWithEmailAndPassword(email, password)
    //   .then(user => {
    //     console.log(user)
        
    //     this.setState({ ...INICIAL_STATE });

    //     if ( history.location.state && history.location.state.nextPathName && history.location.state.redirectoToActivity ){
    //       history.push(history.location.state.nextPathName)
    //     }
    //     else {
    //       history.push(routes.HOME);
    //     }
    //   })
    //   .catch(error => {
    //     this.setState(byPropKey("error", error));
    //   });

    const { email, password } = this.state;
    const { history, onLogin } = this.props;
    
    let redirectoToActivity = null;
    if (history.location.state && history.location.state.nextPathName && history.location.state.redirectoToActivity)
      redirectoToActivity = history.location.state.nextPathName

    onLogin(email, password, redirectoToActivity);

  };

  render() {
    // const { email, password, error } = this.state;
    const { email, password } = this.state;
    const { error } = this.props;
		const isInvalid = password === "" || email === "";

    return (

      <div>
        <Form onSubmit={ (e) => this.onSubmit(e) }>
          <FormGroup>
            <Label for="exampleEmail">Email</Label>
            <Input
              onChange={event => this.setState(byPropKey('email', event.target.value))}
              innerRef={email}
              type="email"
              name="email"
              id="exampleEmail"
              placeholder="insira seu email"
            />
          </FormGroup>
          <FormGroup>
            <Label for="examplePassword">Senha</Label>
            <Input
              onChange={event => this.setState(byPropKey('password', event.target.value))}
              innerRef={password}
              type="password"
              name="password"
              id="examplePassword"
              placeholder="insira sua senha"
            />
          </FormGroup>

          { error && <p>{errorMessages[error.code]}</p> }

          {/* <Button className="w-100" disabled={isInvalid}>Enviar</Button> */}
          <button className="w-100 btn btn-default" disabled={isInvalid} >Entrar</button>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  // error: state.userState.error
  error: state.sessionState.error
})

const mapDispatchToProps = (dispatch) => ({
  onLogin: (email, password, redirectoToActivity) => dispatch(login(email, password, redirectoToActivity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignInForm);