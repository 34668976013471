import { combineReducers } from 'redux';
import sessionReducer from './session';
import agendaReducer from './agenda';
import searchReducer from './search';
import favsReducer from './favs';
import modalReducer from './modal';

const rootReducer = combineReducers({
  sessionState : sessionReducer, 
  agendaState: agendaReducer,
  searchState: searchReducer,
  favsState: favsReducer,
  modalState: modalReducer
});

export default rootReducer;
