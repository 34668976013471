export const userConstants = {
  LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USER_LOGIN_FAILURE',
  
  PROFILE_COMPLETE: 'USER_PROFILE',

  LOGOUT: 'USER_LOGOUT', 
  
  GET_DATA: 'USER_GET_DATA',

  BREADCOMB_RESET: 'BREADCOMB_RESET', 
  BREADCOMB_SUCCESS_CCBNB: 'BREADCOMB_SUCCESS_CCBNB', 
  
}
