import React from "react";

import { withRouter } from "react-router-dom";

import { SignUpLink } from "./SignUp";
import { ResetPasswordLink } from "./SignUp";

import mainColors from '../constants/color'
import SignInForm from "./SignInForm";


const SignInPage = ({ history }) => {
  return (
    // <div className='d-flex flex-column p-3' style={{fontSize: "0.95em", backgroundColor: "#713D63", color: "white", minWidth: 300}}>
    <div className='d-flex flex-column p-3' style={{fontSize: "0.95em", backgroundColor: mainColors.redBNB, color: "white", minWidth: 300}}>
      <SignUpLink />
      <div className="dropdown-divider py-2"></div>
      <h5 className="text-center">Já tenho uma conta</h5>
      <SignInForm history={history} />
      <ResetPasswordLink />
    </div>
  );
};


export default withRouter(SignInPage);
