import React from "react"
import { connect } from "react-redux";
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faClock } from '@fortawesome/free-solid-svg-icons'
import mainColors from '../../constants/color'
import moment from 'moment'

import './AtividadeAgenda.css'
import { setAtividade } from "../../actions/modalActivityActions"


const tituloAtividade = (atividade) => {
  const local = atividade?.origemArtista ? `(${atividade.origemArtista})` : '';
  if (atividade.nome === atividade.grupoArtista) 
    return (
      <div className="title">{atividade.nome} {local}</div>
    )
  else {
    return (
      <div>
        <div className="title">{atividade.nome}</div>
        <i>{atividade.grupoArtista} {local}</i>
      </div>
    )
  }
}

const AtividadeAgenda = ({ item, index, agenda, idAgenda, enviarAtividade }) => {

  let horas = moment(item.evento.dateTime).format('HH[h]');
  let minutos = moment(item.evento.dateTime).format('mm');

  const horaString = minutos !== '00' ? horas + minutos : horas;
  const titulo = tituloAtividade(item.atividade)

  const idOnAgenda = !!agenda ? agenda.id : idAgenda

  return (
    <div key={index} className='py-1' style={{fontSize: 15}}>
      <div className="grey-title"><b>{item.atividade.area.toUpperCase()} - {item.atividade.programa}</b></div>
      
      {titulo}
      
      <div><FontAwesomeIcon color={mainColors.orangeBNB} icon={faClock} fixedWidth/> {horaString}</div>
      <div><FontAwesomeIcon color={mainColors.orangeBNB} icon={faMapMarkerAlt} fixedWidth/> {item.evento.local}</div>
      
      <div className="row">
        <div className="align-self-end py-1 col-md-6 col-sm-12">
          <div className="d-flex justify-content-between flex-wrap">
          
            <div>
              {/* <Link 
                  to={{
                    // pathname: `/agenda/${agenda.id}/atividade/${item.atividade.atividadeId}`,
                    pathname: `/agenda/${idOnAgenda}/atividade/${item.atividade.atividadeId}`,
                    state: {
                      atividade: !idAgenda ? item.atividade : null, 
                      // atividade: item.atividade, 
                      agenda: agenda, 
                      index: index
                    }
                  }} 

                  className="btn btn-outline-primary btn-sm">
                  + Detalhes
                </Link> */}

                <a 
                  onClick={() => enviarAtividade(idOnAgenda, item.atividade.atividadeId)}
                  // onClick={() => enviarAtividade(item.atividade.idAgenda, item.atividade.atividadeId)}
                  className="btn btn-outline-primary btn-sm">
                  + Detalhes
                </a>
              </div>
              
              <div></div>
          </div>
        
        </div>
      </div>
      
      
    </div>  
  )
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser
});

const mapDispatchToProps = (dispatch, props) => ({
  
  // enviarAtividade: (agendaId, atividadeId) => dispatch(setAtividade(props.agenda.id, props.item.atividade.id)),
  enviarAtividade: (agendaId, atividadeId) => dispatch(setAtividade(agendaId, atividadeId)),
  
});

export default connect(mapStateToProps, mapDispatchToProps)(AtividadeAgenda);
