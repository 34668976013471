// import React from 'react';

// import { auth } from '../firebase';

// const SignOutButton = () =>
//   <div onClick={auth.doSignOut}>Sair</div>

// export default SignOutButton;



import React from 'react';
import { auth } from '../firebase';
import { withRouter } from "react-router-dom";
import * as routes from "../constants/routes";

const logout = (history) => {
  auth.doSignOut()
    .then(()=> history.push(routes.LANDING));
    // .then(history.push(routes.LANDING));
  // auth.doSignOut()
  // history.push(routes.LANDING)
}

const SignOutButton = ({ history }) => {
  return (
    <div onClick={() => logout(history)}>Sair</div>
  )
}
export default withRouter(SignOutButton);

