import { InputGroup, InputGroupAddon, Input } from 'reactstrap';

const BuscarInput = ({onChange, inputTextValue, placeholder, onClickClear}) => 
  <InputGroup style={{zIndex: 1}}>
    <Input  
      onChange={onChange} 
      value={inputTextValue} 
      placeholder={placeholder}
    />
    <InputGroupAddon addonType="append" onClick={() => onClickClear()}>
      X
    </InputGroupAddon>
  </InputGroup>

export default BuscarInput;