import { db } from './firebase';

export const doCreateUser = (id, username, ccbnb) => 
  db.ref(`users/${id}`).set({
    username, 
    ccbnb, 
  });

// export const doAddUserToken = (userId, token) => {
//   const update = ['users/' + userId + '/tokens/' + token] = true;
//   return db.ref().update(update);
// }

export const doAddUserToken = (userId, token) => 
  // db.ref(`users/${userId}/tokens/${token}`).update({ on: true });
  db.ref(`users/${userId}/tokens/`).child(`${token}`).set(true);
    
export const onceGetUser = (id) =>
  db.ref(`users/${id}`).once('value');

export const doCreateFav = (user_id, agenda, atividade) => {

  
  db.ref(`favs/${user_id}/agendas/${agenda.id}/`).update({
    nome: agenda.nome, 
    ccbnb: agenda.ccbnb,
    mes: agenda.mes
    
  });
  
  return db.ref(`favs/${user_id}/agendas/${agenda.id}/atividades/${atividade.atividadeId}`).set({
    atividade_id : atividade.id, 
    nome: atividade.nome, 
    area: atividade.area, 
    programa: atividade.programa,
  });

  
}  

export const doRemoveFav = (user_id, agenda_id, atividade) => {

  return db.ref(`favs/${user_id}/agendas/${agenda_id}/atividades/${atividade.atividadeId}`).remove();

  
}  

export const onceGetFavsByAgenda = (user_id, agenda_id) =>
  db.ref(`favs/${user_id}/agendas/${agenda_id}`).once('value');

export const onceGetAllFavs = (user_id) =>
  db.ref(`favs/${user_id}`).once('value');


// import { dbfire } from './firebase';

// export const doCreateUser = (id, username, email) => 
//   dbfire.collection('users').doc(id).set({
//     username, email
//   });


// export const onceGetUser = (id) =>
//   dbfire.collection('users').doc(id).get();

