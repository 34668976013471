import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { firebasedb } from '../firebase';

import mainColors from '../constants/color'
import ReactLoading from 'react-loading';

// import { renderAtividadeOnly } from '../components/AgendaPorArea'
import AtividadeResultadoBusca from './AgendaBusca/AtividadeResultadoBusca'
import { getLocalFavs, setLocalFavs} from '../services/favsLocalService'

import { isEmpty } from 'lodash'
  
class FavsPage extends Component {

  constructor(props) {
    super(props); 
		
    this.state = {
      allFavs: null,
      loading: true,
    }
    // this.simpleGetAgendaFavs();

    this.handleClickDetails.bind(this)
  }

  setFavs = (favs) => {
    
    this.setState({
      ...this.state, 
      allFavs: favs, 
      loading: false,
    })
  }


  simpleGetAgendaFavs = async () => {

    const { agenda, atividades, favs, loading } = this.props;

    if (!loading) {

      if (favs && favs !== undefined && favs[agenda.id]) {
        const result = atividades.filter( ativ => favs[agenda.id].includes(ativ.atividadeId) )
        
        this.setFavs(result);
        // setLocalFavs(agenda, result);
      } else {
        this.setFavs(null);
      }

    }
    
    
  }

  getAgendaFavs = (user, agenda) => {

    const localFavs = getLocalFavs(agenda)
		
    if (localFavs) {

      const favsStorage = JSON.parse(localFavs);
      this.setFavs(favsStorage);

    } 
    // else {

    firebasedb.onceGetFavsByAgenda(user.uid, agenda.id).then((favs) => {
      const favsObj = favs.val();
      if (favsObj && favsObj !== undefined && favsObj.atividades) {

        const favsArray = Object.keys(favsObj.atividades).reduce((val, favId) => {
          val.push(favId);
          return val;
        }, [])
        const { atividades } = this.props;
        const result = atividades.filter(ativ => favsArray.includes(ativ.atividadeId))
        
        this.setFavs(result);
        setLocalFavs(agenda, result);

      } else {
        this.setFavs(null);
      }        
    })
      

    // }
  
  }
  
  handleClickDetails = (agenda, atividade) => {

    this.props.history.push({
      pathname: `/agenda/${agenda.id}/atividade/${atividade.atividadeId}`,
      state: {
        atividade: atividade, 
        agenda: agenda, 
        index: `${atividade.atividadeId}`,
        fav: true
      }
    })
  }

  

  // componentDidMount = async () => {

  //   // const { user, agenda }  = this.props;

	// 	// if ( user && agenda ) {  
  //   //   this.getAgendaFavs(user, agenda);
  //   // } else {

  //   //   // not a pretty fix
  //   //   setTimeout(() => {
  //   //     this.componentDidMount()
  //   //   }, 1000)

  //   // }

  //   this.simpleGetAgendaFavs();
  // }

  componentWillReceiveProps = () => {
    
    this.simpleGetAgendaFavs();

  }
  

  render() {

		// const { allFavs, loading } = this.state;
    // const { agenda } = this.props;

    // const { allFavs } = this.state;
    const { agenda, loading, allFavs } = this.props;
		

    if (loading) {
      
      return (
        <div style={{ minHeight: '70vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
          <ReactLoading type={'spin'} color={mainColors.orangeBNB} height={'20%'} width={'20%'} />  
        </div>          
      )
      
    }

    else if (isEmpty(allFavs)) {
      
      return (<div className="container my-3">Você ainda não tem atividades favoritadas. </div>);
      
    } else {
      return (
        <div className="my-3">
          {/* { allFavs.map( (item, index) => renderAtividadeOnly(item, index, agenda) ) }   */}
          { allFavs.map( (item, index) => <AtividadeResultadoBusca key={index} item={item} agenda={agenda}  /> ) }  
        </div>
      )
    }
    
    
    
    
      
    
    
  }
}

const getAllFavs = (agenda, atividades, favs) => {  
  
  if (favs && favs !== undefined && favs[agenda.id]) {
    const result = atividades.filter( ativ => favs[agenda.id].includes(ativ.atividadeId) )
    
    return result
    // setLocalFavs(agenda, result);
  } else {
    return {};
  }

}

const mapStateToProps = state => ({
  user: state.sessionState.authUser,
  agenda: state.agendaState.agenda, 
  atividades: state.agendaState.atividades,
  favs: state.favsState.favs,
  loading: state.favsState.fetching,
  allFavs: getAllFavs(state.agendaState.agenda, state.agendaState.atividades, state.favsState.favs)
});


export default withRouter(connect(mapStateToProps)(FavsPage));