import { modalActivityConstants } from '../constants/modalActivityConstants';
import ReactGA from 'react-ga'
import { getAtividadeAnyway, getAtividadeAnyway2 } from '../services/agendaService';



export const toogleModal = () => ({
  type: modalActivityConstants.MODAL_TOOGLE
})

export const fetching = (isFetching) => ({
  type: modalActivityConstants.MODAL_TOOGLE,
  modalFetching: isFetching
})

export const error = (error) => {
  console.log("🚀 ~ file: modalActivityActions.js:17 ~ error ~ error", error)
  
  return ({
    type: modalActivityConstants.MODAL_FETCH_ERROR,
    modalError: error
  })
}



export const setAtividade = (idAgenda, idAtividade) => {
  console.log("🚀 ~ file: modalActivityActions.js:28 ~ setAtividade ~ idAgenda, idAtividade:", idAgenda, idAtividade)
  
  return async function getAtividade (dispatch, getState) {
    
    
    dispatch({
      type: modalActivityConstants.MODAL_CHOOSEN_ACTIVITIY,
      atividadeModal: null
      // atividadeModal: {"release":"A oficina proporciona a experiência de confeccionar massinha de modelar artesanal, trabalhando as cores, texturas e formas, bem como a função motora dos pequeninos. Em seguida, esta massinha ganha vida pelas mãos talentosas das crianças que, utilizando imaginação e inventividade, criam os enfeites natalinos com suas próprias expressões. Jussy é arte educadora, mãe e exploradora do universo infantil. Público alvo: crianças a partir de 06 anos. 20 vagas. ","links":[],"origemArtista":"Sousa - PB","imagem":"https://firebasestorage.googleapis.com/v0/b/agendaccbnb.appspot.com/o/agenda%2FgSetIenj2zeTZ5ih2D6p%2Fatividade%2Fb0A8uq7FLumct5a4wbVd%2FDesign%20sem%20nome%20(4).jpg?alt=media&token=0df316cf-c8fe-49dd-822f-1e571a0571e6","duracao":90,"classificacao":"livre","nome":"Oficina de arte: Massinha de modelar - A fábrica modelada de Natal","grupoArtista":"Jussy Sousa","eventos":[{"dateTime":{"seconds":1701547200,"nanoseconds":0},"local":"Sala de Oficinas - CCBNB Sousa"},{"dateTime":{"seconds":1702756800,"nanoseconds":0},"local":"Sala de Oficinas - CCBNB Sousa"}],"id":"b0A8uq7FLumct5a4wbVd","programa":"Criança e Arte - Oficina","area":"Humanidades","youtube":null,"atividadeId":"b0A8uq7FLumct5a4wbVd"}
    })


    dispatch(fetching(true))

    // setTimeout(async () => {
    //   dispatch(fetching(true))  
    // }, 200);
    
    return getAtividadeAnyway2(idAgenda, idAtividade)
    .then(result => {
      console.log("🚀 ~ file: modalActivityActions.js:31 ~ getAtividade ~ result", result, idAgenda, idAtividade)
      
      if (result?.error) {

        // console.log("AUQIIIIIIIi")


        dispatch(fetching(false))
        dispatch(error(result.error))
        dispatch(toogleModal())

      } else {

        // console.log("🚀 ~ file: modalActivityActions.js:46 ~ getAtividade ~ atividade", atividade)
        const atividade = {...result, idAgenda: idAgenda, idAtividade: idAtividade};
  
        ReactGA.event({
          category: 'Atividade',
          action: `Visit Atividade ${atividade.nome.toUpperCase()} - ${atividade.id}`,
          label: `${atividade.nome.toUpperCase()}`
        });
        
        
        dispatch({
          type: modalActivityConstants.MODAL_CHOOSEN_ACTIVITIY,
          atividadeModal: atividade,
        })

        dispatch(fetching(false))
        
        dispatch(toogleModal())
        

      }
    })
    .catch((err) => {

      console.log("e aqui? ", err)

    }) 
  
  }
    console.log("🚀 ~ file: modalActivityActions.js:90 ~ getAtividade ~ idAtividade:", idAtividade)
    console.log("🚀 ~ file: modalActivityActions.js:90 ~ getAtividade ~ idAtividade:", idAtividade)
    console.log("🚀 ~ file: modalActivityActions.js:90 ~ getAtividade ~ idAtividade:", idAtividade)
    console.log("🚀 ~ file: modalActivityActions.js:90 ~ getAtividade ~ idAtividade:", idAtividade)
    console.log("🚀 ~ file: modalActivityActions.js:90 ~ getAtividade ~ idAtividade:", idAtividade)
    console.log("🚀 ~ file: modalActivityActions.js:90 ~ getAtividade ~ idAgenda, idAtividade:", idAgenda, idAtividade)
    console.log("🚀 ~ file: modalActivityActions.js:90 ~ getAtividade ~ idAgenda, idAtividade:", idAgenda, idAtividade)
    console.log("🚀 ~ file: modalActivityActions.js:90 ~ getAtividade ~ idAgenda, idAtividade:", idAgenda, idAtividade)
    console.log("🚀 ~ file: modalActivityActions.js:90 ~ getAtividade ~ idAgenda:", idAgenda)
  
}






  
// const openModal = (atividadeModal) => 

//   (dispatch) => {
    
//     ReactGA.event({
//       category: 'Atividade',
//       action: `Visit Atividade ${atividadeModal.nome.toUpperCase()} - ${atividadeModal.id}`,
//       label: `${atividadeModal.nome.toUpperCase()}`
//     });

    
//     dispatch(setAtividade(atividadeModal)) 
//     dispatch(toogleModal())

//   }



  