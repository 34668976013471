import React, { Component } from 'react';

import { connect } from "react-redux";

import 'moment/locale/pt-br';


import { InputGroup, InputGroupAddon, Input } from 'reactstrap';

import { agendaMensal, ativsByArea, ativsByDay } from './AgendaHelpers'
import { debounce } from 'lodash';
import ResultsByArea from './AgendaBusca/ResultsByArea';
import BuscarInput from './BuscarInput';
import AgendaCalendario from './AgendaCalendario';
import { agendaByDay, filterByDay, filteredDays } from '../services/agendaService';
import AgendaAreaButtons from './AgendaAreaButtons/AgendaAreaButtons';



const replaceSpecialChars = (str) => {
    str = str.replace(/[ÀÁÂÃÄÅ]/,"A");
    str = str.replace(/[àáâãäå]/,"a");
    str = str.replace(/[ÈÉÊË]/,"E");
    str = str.replace(/[ÍÌÎ]/,"I");
    str = str.replace(/[íìî]/,"i");
    str = str.replace(/[ÓÒÕÔ]/,"O");
    str = str.replace(/[óòõô]/,"o");
    str = str.replace(/[ÚÙÛ]/,"U");
    str = str.replace(/[úùû]/,"u");
    str = str.replace(/[Ç]/,"C");
    str = str.replace(/[ç]/,"c");
    // o resto

    return str.replace(/[^a-z0-9]/gi,''); 
}

const storeNameSearchOnThisAgenda = agenda => `searchLocalStateOnAgenda/${agenda.id}`;

  
class AgendaPorArea extends Component {  

  constructor(props) {
    super(props);

    this.state = {
      inputText: ''
    }

    this.onChange.bind(this)
    // delete the line above to fix scrollmanager
    // this.changeText = debounce(this.changeText, 400);
    this.changeText = debounce(this.changeTextSimple, 400);

    this.changeText.bind(this)

  }
  
  changeTextSimple = (text) => {
    
    const { agenda, days, onSetSearchText, onSetSearchResults, onResetSearch } = this.props;
    const atividades = this.props.atividades;


    if ( text && text !== null && text !== '') {

      let filtered = atividades.filter(a =>
        replaceSpecialChars(a.grupoArtista.toUpperCase()).includes(replaceSpecialChars(text.toUpperCase())) || 
        replaceSpecialChars(a.nome.toUpperCase()).includes(replaceSpecialChars(text.toUpperCase())) ||
        replaceSpecialChars(a.programa.toUpperCase()).includes(replaceSpecialChars(text.toUpperCase())) ||
        replaceSpecialChars(a.area.toUpperCase()).includes(replaceSpecialChars(text.toUpperCase()))
      );
      

      if (filtered && filtered !== undefined) {
        
        const aByDay = ativsByDay(filtered);
        const _filteredDays = filteredDays(filtered, days)
        const _agendaMensal = agendaMensal(_filteredDays, aByDay);
        
        // this.setState({
        //   ...this.state, 
        //   filtered: filtered, 
        //   byDay: _agendaMensal
        // });

        onSetSearchResults(_agendaMensal)
        onSetSearchText(text);
        
        localStorage.setItem(storeNameSearchOnThisAgenda(agenda), JSON.stringify({ text, resultsByDay: _agendaMensal }))
  
      }

      this.setState({
        ...this.state, 
        inputText: text,

      });

      onSetSearchText(text);

      

    } else {


      this.setState({
        ...this.state, 
        inputText: "",
      });

      // onSetSearchText("");
      // onSetSearchResults([]);
      onResetSearch()

      localStorage.removeItem(storeNameSearchOnThisAgenda(agenda))

    }

  }

  onChange = (event) => {
    
    const text = event.target.value;

    this.setState({
      ...this.state, 
      inputText: text,
    })
    
    this.changeText(text);

    event.preventDefault()
    
  }

  


  componentDidMount = async () => {

    const { agenda, onSetSearchText, onSetSearchResults  } = this.props;

    const localStorageSearchOnThisAgenda = localStorage.getItem(storeNameSearchOnThisAgenda(agenda));

    if (!!localStorageSearchOnThisAgenda) {

      const searchLocalStorage = JSON.parse(localStorageSearchOnThisAgenda);
      const { text, resultsByDay } = searchLocalStorage;
      
      onSetSearchText(text)
      onSetSearchResults(resultsByDay)

      this.setState({
        ...this.state, 
        inputText: text,
      })

      // this.changeTextSimple(text);

    } 

  }
  

  render() {
    
    const { searchResults } = this.props;

    const { agenda } = this.props;
    
      return (

        <div className="">
          <div className="my-3">
            <BuscarInput 
              onChange={this.onChange}
              inputTextValue={this.state.inputText}
              placeholder={'Buscar'}
              onClickClear={() => this.changeText('')}
            />
          </div>
          <AgendaAreaButtons onClickAction={this.changeText} />

          { searchResults && searchResults.length > 0 && <AgendaCalendario agenda={agenda} byDay={searchResults} />}
        </div>
      );
    

    
  }
}

const mapStateToProps = state => ({
  agenda: state.agendaState.agenda,
  byArea: state.agendaState.byArea,
  days: state.agendaState.days,
  atividades: state.agendaState.atividades,
  searchText: state.searchState.text,
  searchResults: state.searchState.results,

});

const mapDispatchToProps = (dispatch) => ({
  onSetSearchText: (search) => dispatch({ type: 'SEARCH_TEXT', data: { text: search } } ),
  onSetSearchResults: (results) => dispatch({ type: 'SEARCH_RESULT', data: { results } } ),
  onResetSearch: () => dispatch({ type: 'SEARCH_CLEAR' }),
})

export default connect(mapStateToProps, mapDispatchToProps)(AgendaPorArea);
