import { modalActivityConstants } from '../constants/modalActivityConstants';

const ativ = {

  area: "Artes Cênicas", 
  atividadeId: "IrQG2jqTZBaXzg7FW3XM", 
  classificacao: "livre", 
  duracao: 50, 
  grupoArtista: "Palhaço Piruá", 
  id: "IrQG2jqTZBaXzg7FW3XM", 
  idAgenda: "vDDC6OwAstLxpWAmpRCy", 
  // imagem: "https://firebasestorage.googleapis.com/v0/b/agendaccbnb.apps…ZUL.jpg?alt=media&token=f6b36db4-9e7b-4437-ac7c-988c3fdfd671", 
  imagem: "https://firebasestorage.googleapis.com/v0/b/agendaccbnb.appspot.com/o/agenda%2FvDDC6OwAstLxpWAmpRCy%2Fatividade%2FuijJRaxqoCht9jhg8tvx%2FB7%20e%2028%20-%20tres%20sessoes%20-%20As%20criac%CC%A7o%CC%83es%20de%20um%20Ze%CC%81%20Um%20poc%CC%A7o%20de%20Cultura.jpg?alt=media&token=4bee5098-e6b8-44ab-b4c7-799597b61ea8", 
  nome: "Piruá de Circo", 
  origemArtista: "Natal - RN", 
  programa: "Criança e Arte - Teatro", 
  youtube: null,
  eventos: []
}


const INITIAL_STATE = {
  // atividadeModal: ativ,
  atividadeModal: null,
  openModal: false,
  modalFetching: false, 
  modalError: null
};


const applySetAtividade = (state, action) => {
  return ({
    ...state, 
    atividadeModal: action.atividadeModal
  });

}

const toogleModal = (state) => {
  const { openModal } = state
  return ({
    ...state, 
    openModal: !openModal
  });
}

const setFetching = (state, action) => {
  return ({
    ...state, 
    modalFetching: action.modalFetching
  });
}

const setError = (state, action) => {
  console.log("🚀 ~ file: modal.js:55 ~ setError ~ action", action)
  return ({
    ...state, 
    modalError: action.modalError
  });
}


function modalReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case modalActivityConstants.MODAL_TOOGLE: {
      return toogleModal(state);
    }
    case modalActivityConstants.MODAL_CHOOSEN_ACTIVITIY: {
      return applySetAtividade(state, action);
    }

    case modalActivityConstants.MODAL_FETCHING: {
      return setFetching(state, action);
    }

    case modalActivityConstants.MODAL_FETCH_ERROR: {
      return setError(state, action);
    }
    default: return state;
  }
}

export default modalReducer;