import React from 'react'
import * as routes from "../constants/routes";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faKey } from '@fortawesome/free-solid-svg-icons';

import { isAValidUser } from '../actions/sessionActions'

const AlertToLogin = (props) => {
  

  const { authUser } = props
  let isCompleted = null;
  
  if (authUser)
    isCompleted = isAValidUser(authUser) 


  if ( authUser && isCompleted ) {
    return <></>
  }

  if ( authUser && !isCompleted ) {
    return (
      
      <div className='alert' style={{background: "hsla(0, 80%, 80%, 0.6)", boxShadow: "1px 1px 0  red"  }}>
        <FontAwesomeIcon icon={faExclamationTriangle}  fixedWidth /> {' '} 
        <span>Para melhor experiência, </span>
          <u>
          <Link to={routes.PROFILE}>
            {`complete seu cadastro. `}
          </Link>
          </u>
      </div>
    )
  }


  return (

    
      // <div className='alert' style={{background: "hsla(0, 80%, 80%, 0.6)", boxShadow: "1px 1px 0  red"  }}>
      // <div className='alert alert-warning' style={{fontSize: "1.2em", border: "1px 2px solid red"}}  >
      <div className='alert alert-warning' >
        <FontAwesomeIcon icon={faKey}  fixedWidth /> {' '} 
        
        <span>Para receber mais informações, </span>
          <u>
          <Link to={routes.ENTRAR}>
            {`faça seu login. `}
          </Link>
          </u>
      </div>
    
  )
}


const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  // isCompleted: state.sessionState.isCompleted,
});

export default connect(mapStateToProps)(AlertToLogin);

