import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import mainColors from '../../constants/color';
;

export const ContainerAreaButons = styled.div`
  display: flex;
  /* max-width: 100%; */
  flex-wrap: wrap;
`

export const Button = styled.button`
  /* min-width: 100px; */
  /* flex: 1; */
  flex-grow: 2;
  
  background-color: transparent; 
  color: #A80834;
  font-size: 0.875em;
  margin: 1px;
  padding-top: 3px;
  padding-bottom: 3px;
  
  border: 1px solid red;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background-color: #A80834; 
    color: white;
  }

`
