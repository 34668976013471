import React, {useState} from "react";

import "./App.css";

// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Router, Route, Switch } from "react-router-dom";
import { history } from '../constants/history'

import Navigation from "./Navigation/Navigation";
import LandingPage from "./Landing/";
import SignUpPage from "./SignUp";
import SingInPage from "./SignIn";
import PasswordForgetPage from "./PasswordForget";
import HomePage from "./Home";
import AccountPage from "./Account/AccountPage";

import * as routes from "../constants/routes";

// import withAuthentication from "./withAuthentication";
import Agenda from "./Agenda";
import Atividade from "./Atividade/index";
import FavsPage from "./FavsPage";
import ResetPassword from "./ResetPassword";
import Entrar from "./Entrar";

//toast container
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import SelectorCcbnb from "./SelectorCcbnb";
import withAuthentication from "./withAuthentication";

// analytics
import { initGa } from '../helpers/analytics'
import Profile from "./Profile";

import { Helmet } from "react-helmet";
import Atividade2 from "./Atividade2";


initGa();

const App = () => {
  
  const [modal, setModal] = useState({ 
    agenda: null, 
    atividade: null
  });


  return (
    <Router history={history}>  
    
      <div>
        <Helmet>
          <title>Agenda CCBNB</title>
          <meta charSet="utf-8" />
          <meta property="og:site_name" content="Agenda CCBNB"></meta>
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta property="og:url" content="http://agendaccbnb.com.br" ></meta>
          <link rel="canonical" href="http://agendaccbnb.com.br" />
        </Helmet>
        <Navigation />
        <div className="rest">

          <Switch>
            {/* <Route exact path={routes.LANDING} component={LandingPage} /> */}
            <Route exact path={routes.LANDING2} component={LandingPage} />
            {/* <Route exact path={routes.LANDING} component={SelectorCcbnb} /> */}
            <Route exact path={routes.SELECT} component={SelectorCcbnb} />
            <Route exact path={routes.SIGN_UP} component={SignUpPage} />
            <Route exact path={routes.SIGN_IN} component={SingInPage} />
            <Route exact path={routes.PASSWORD_FORGET}component={PasswordForgetPage}/>
            <Route exact path={routes.HOME} component={HomePage} />
            <Route path={routes.CCBNB} component={HomePage} />
            <Route exact path={routes.ACCOUNT} component={AccountPage} />
            <Route exact path={routes.AGENDA}  component={Agenda} />
            {/* <Route path={routes.ATIVIDADE}  component={Atividade} /> */}

            {/* <Route exact path={routes.ATIVIDADE3}  component={Atividade} /> */}
            {/* <Route exact path={routes.ATIVIDADE3}  render={(props)=> <Atividade {...props} />} /> */}
            <Route exact path={routes.ATIVIDADE3}  render={(props)=> <Atividade2 {...props} />} />

            {/* <Route exact path={routes.AGENDA2}  component={Agenda} /> */}
            <Route path={routes.FAVS}  component={FavsPage} />
            <Route path={routes.RESET_PASSWORD}  component={ResetPassword} />
            <Route path={routes.ENTRAR}  component={Entrar} />
            <Route path={routes.PROFILE}  component={Profile} />
          </Switch>

        </div>
        
        <ToastContainer />
      </div>  
    </Router>   
  )
}

export default withAuthentication(App);
