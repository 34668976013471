import { favsConstants } from '../constants/favsConstants'
import { firebasedb } from '../firebase';
import { getLocalFavs, setLocalFavs} from '../services/favsLocalService'
import { pull } from 'lodash';

export const favReset = () => (dispatch) => {
  //before, remove all fav localstates
  dispatch({type: favsConstants.FAV_RESET})
}

export const setAgendaFavs = (agendaId, ativs) => 
  (dispatch, getState) => {
    let favs = getState().favsState.favs  
    if (favs && favs[agendaId]) {
      favs[agendaId] = favs[agendaId] || []
    } else {
      favs = {}
      favs[agendaId] = []
    }
    // favs[agendaId] = favs[agendaId].concat(ativs);
    favs[agendaId] = ativs;
    dispatch({type: favsConstants.FAV_SET, favs })
  }


export const fetchAgendaFavs = (userId, agendaId) => (dispatch) => {

  dispatch({type: favsConstants.FAV_FETCHING })

  const localFavs = getLocalFavs(agendaId);
  if ( localFavs && localFavs.atividades ) {
    const favsArray = Object.keys(localFavs.atividades);
    dispatch(setAgendaFavs( agendaId, favsArray ))
  }
   
  
  firebasedb.onceGetFavsByAgenda(userId, agendaId)
    .then( (snapshot) => {
      const favs = snapshot.val()

      setLocalFavs(agendaId, favs)
      
      if (favs && favs.atividades) {
        const favsArray = Object.keys(favs.atividades);
        dispatch(setAgendaFavs( agendaId, favsArray ))
        
      } else  {
        dispatch(setAgendaFavs( agendaId, null ))
      }
      

      
    })
}


export const applyRemoveFav = (agendaId, atividadeId) => (dispatch, getState) => {
  
  let favs = getState().favsState.favs  

  if (favs && favs[agendaId]) {
    const favsAgenda = favs[agendaId] || [];
    
    pull(favsAgenda, atividadeId);

    const newFavs = { ...favs, [agendaId] : favsAgenda }
		
    dispatch({type: favsConstants.FAV_SET, newFavs })
  }

}

export const applyAddFav = (agendaId, atividadeId) => (dispatch, getState) => {
  
  let allFavs = getState().favsState.favs 

  const favsAgenda = allFavs && allFavs[agendaId] ? allFavs[agendaId] : []

  const newAgendaFavs = favsAgenda.push(atividadeId)

  allFavs = { allFavs, [agendaId]: newAgendaFavs } 
  
  dispatch({type: favsConstants.FAV_SET, allFavs })

}