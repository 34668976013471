const INITIAL_STATE = { 
  isSearching: false,
  searchText:  "", 
  results: []
};

// const applySetSearch = (state, action) => {

//   const { agenda, search } = action.data; 
  
//   let resultState = {
//     ...state, 
//     [agenda] : search,
//   }
//   return (resultState);

// }

const applyTextSearch = (state, action) => {

  const { text } = action.data; 
  
  let resultState = {
    ...state, 
    isSearching: true,
    searchText: text,
  }
  return resultState;

}

const applyTextResults = (state, action) => {

  const { results } = action.data; 
  
  let resultState = {
    ...state, 
    isSearching: true,
    results : results,
  }
  return resultState;

}

const applyClearSearch = (state) => {
  
  let resultState = {
    ...state, 
    isSearching: false,
    searchText: "", 
    results : [],
  }
  return resultState;

}


function searchReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // case 'SEARCH_SET': {
    //   return applySetSearch(state, action);
    // }
    case 'SEARCH_TEXT': 
      return applyTextSearch(state, action);
    case 'SEARCH_RESULT': 
      return applyTextResults(state, action);
    case 'SEARCH_CLEAR': 
      return applyClearSearch(state);
    default: return state;
  }
}

export default searchReducer;