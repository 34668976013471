import React, { Component } from 'react';
import { connect } from "react-redux";
import { firebasedb } from '../firebase';

import ScrollManager from './ScrollManager';


class FavsPage extends Component {

  constructor(props) {
    super(props); 
    this.state = {
      allFavs: null,
      loading: true,
    }

  }
  
  getActivity = (agenda, atividadeId) => {
    let storeNameAgenda = `agendaState/${agenda.id}`;
    let agendaSessionStorageString = localStorage.getItem(storeNameAgenda);
    
    if (agendaSessionStorageString) {

      let agendaSessionStorage = JSON.parse(agendaSessionStorageString);
      let { agenda, atividades } = agendaSessionStorage;
      let atividade = atividades.filter( s => s.atividadeId === atividadeId )[0];

      this.props.history.push({
        // pathname: `/atividade/${atividadeId}`,
        pathname: `/agenda/${agenda.id}/atividade/${atividadeId}`,
        state: {
          atividade: atividade, 
          agenda: agenda, 
          index: `${atividadeId}`,
          fav: true
        }
      })
      
    } else {

      console.log('ainda tem que buscar a agenda e a atividade para encaminhar a rota');
      
    }
  }


  componentDidMount = () => {

    const { user }  = this.props;


    if ( user ) {
      
      console.log('Getting favs on firebase realtime');
      
      firebasedb.onceGetAllFavs(user.uid).then((favs) => {
        let favsObj = favs.val();

        if (favsObj) {

          let favsArray = Object.keys(favsObj.agendas).reduce((val, favId) => {
            let agenda = { ...favsObj.agendas[favId], id: favId }
            val.push(agenda);
            return val;
          }, [])
  
          favsArray.sort((b, a) => {
            return a.mes.seconds - b.mes.seconds;
          });
          
          this.setState({
            // allFavs : favs.val(), 
            allFavs : favsArray, 
            loading: false,
          });

        } else {

          this.setState({
            allFavs : null, 
            loading: false,
          });

        }        
      })
    }
    
  }

  
  

  render() {
    
    const { allFavs, loading } = this.state;

    if (!loading) {

      if (allFavs && allFavs !== undefined) {
        return (
          <div className="container my-3">
            <ScrollManager scrollKey="favsPageUser" />
            {/* {Object.keys(allFavs.agendas).map((agenda, index) => { */}
            { allFavs.map((agenda, index) => {
              return (
    
                <div key={agenda.id}>
                  
                  {/* <div style={{backgroundColor: mainColors.orangeBNB , padding: 10, color: 'white', marginBottom: 10,}}> */}
                  <div>
                    {/* <b>{allFavs.agendas[agenda].nome.toUpperCase()} - {allFavs.agendas[agenda].ccbnb.toUpperCase()}</b> */}
                    <b>{agenda.nome.toUpperCase()} - {agenda.ccbnb.toUpperCase()}</b>
                  </div>
    
                  {/* { allFavs.agendas[agenda].atividades && Object.keys(allFavs.agendas[agenda].atividades).map((item, index) => { */}
                  { agenda.atividades && Object.keys(agenda.atividades).map((item, index) => {
                    return (
                      <div key={item} className='mb-3'>
                        <div>{ agenda.atividades[item].area } - { agenda.atividades[item].programa } </div>
                        <div>{ agenda.atividades[item].nome } </div>

                        <button className="btn btn-outline-primary my-2" onClick={() => this.getActivity(agenda, item)}>
                          + Detalhes
                        </button>
                        
                      </div>
                    )
                  })}
                </div>
    
                
              );
            })}
  
          </div>
        );
      } else {
        return (<div className="container my-3">Você ainda não tem atividades favoritadas. </div>);
      }

    } else {
      // return (<div className="container my-3"></div>);
      return (<div className="container my-3">Você ainda não tem atividades favoritadas. </div>);
    }
    
    

    
  }
}

const mapStateToProps = state => ({
  user: state.sessionState.authUser,
});


export default connect(mapStateToProps)(FavsPage);