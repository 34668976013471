import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import * as routes from "../constants/routes";

import { auth, firebasedb } from "../firebase";
// import firebase from 'firebase';
import firebase from 'firebase/compat/app';

import { Button, Form, FormGroup, Label, Input, FormFeedback } from "reactstrap";

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

const INICIAL_STATE = {
  username: "",
  email: "",
  passwordOne: "",
	passwordTwo: "",
  ccbnb: "-",
  error: null, 
  validForm: null,
  validations: {
    username: { pass: null },
    ccbnb: { pass: null },
    password: { pass: null },
  }
};

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  // signInFlow: 'popup',
  signInFlow: 'redirect',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  // signInSuccessUrl: '/signedIn',
  signInSuccessUrl: '/',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  ]
};

const SignUpPage = ({ history }) => {

  firebase.auth().languageCode = 'pt-br'
  
  return (
    <div className='container'>
      
      <div className='col-md-8 offset-md-2'>
        <h2 className='text-center my-3'>Cadastre-se</h2>  
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
        {/* <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={ptbr_auth} /> */}
        <SignUpForm history={history} />
      </div>
    </div>
  );
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value
});

class SignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INICIAL_STATE };
  }

  validateName = () => {
    const { username } = this.state;
    return !(username && username !== "");
  }

  validateForm = () => {
    const { username, ccbnb, passwordOne, passwordTwo, } = this.state
    const validations = { }

    validations.username = !(username && username !== "") ? { invalid: true } : { invalid: false, message: 'Informe seu nome' }
    
    
    validations.ccbnb = !(ccbnb && (['SOUSA', 'FORTALEZA', 'CARIRI'].includes(ccbnb))) ? { invalid: true, message: 'Escolha um CCBNB de sua preferência' } : { invalid: false }
    
    let result= {};
    if (!passwordOne || !passwordTwo || passwordOne === "" || passwordTwo === "") {
      result = { invalid: true, message: 'Senha ou confirmação de senha vazios.' }
    } else if (passwordOne && passwordTwo && passwordOne !== passwordTwo) {
      result = { invalid: true, message: 'As senhas digitadas são diferentes. Digite novamente.' }
    } else {
      result = { invalid: false }
    }
    
    
    validations.password = result;
		
    const validForm = 
      !validations.username.invalid &&
      !validations.ccbnb.invalid &&
      !validations.password.invalid;

    this.setState({
      ...this.state, 
      validForm,
      validations
    });
  }
  
  

  onSubmit = async (event) => {

    event.preventDefault();
    
    await this.validateForm();

    if (this.state.validForm) {
      console.log('Continua');

      const { email, passwordOne, username, ccbnb  } = this.state;
      const { history } = this.props;

      auth
        .doCreateUserWithEmailAndPassword(email, passwordOne)
        .then(authUser => {
          firebasedb.doCreateUser(authUser.user.uid, username, email, ccbnb);
          this.setState({ ...INICIAL_STATE });
          history.push(routes.HOME);
        })
        .catch(error => {
          this.setState(byPropKey("error", error));
        });
    }
      
    
    

    
  };

  render() {
    const { username, email, passwordOne, passwordTwo, error, ccbnb } = this.state;
		
    // const isInvalid =
    //   passwordOne !== passwordTwo ||
    //   passwordOne === "" ||
    //   email === "" ||
		// 	username === "" || 
    //   sexo === "" || 
    //   dtNascimento === "";

    return (
      <div className="m-3 pb-3">
        <Form onSubmitCapture={this.onSubmit}>
        {/* <Form > */}
        {/* <Form> */}
          <FormGroup>
            <Label for="username">Nome Completo</Label>
            <Input
              // value={username}
              innerRef={username}
              type="text"
              placeholder="Nome Completo"
              onChange={event =>
                this.setState(byPropKey("username", event.target.value))
              }
              // invalid={this.validateName()}
              invalid={this.state.validations.username.invalid}
              
            />
          </FormGroup>

					
          <FormGroup>
            <Label for="exampleEmail">Interessado em seguir as agendas do CCBNB:</Label>
            <Input 
              innerRef={ccbnb}
              type="select" 
              name="ccbnb" 
              onChange={event => this.setState(byPropKey("ccbnb", event.target.value))}
              invalid={this.state.validations.ccbnb.invalid}
            >
              <option value="-">--- Escolha um CCBNB ---</option>
              <option value="CARIRI">CARIRI</option>
              <option value="FORTALEZA">FORTALEZA</option>
              <option value="SOUSA">SOUSA</option>
            </Input>
            {/* <Input hidden={true} invalid /> */}
            { this.state.validations.ccbnb && this.state.validations.ccbnb.invalid && <FormFeedback >{this.state.validations.ccbnb.message}</FormFeedback> } 
          </FormGroup>

          <FormGroup>
            <Label for="exampleEmail">Email</Label>
            <Input
              // value={email}
              innerRef={email}
              type="email"
              placeholder="Email"
              onChange={event =>
                this.setState(byPropKey("email", event.target.value))
              }
              invalid={ error }
            />
            <Input hidden={true} invalid />
            { error && error.code === `auth/invalid-email` && <FormFeedback >Email inválido</FormFeedback> } 
            { error && error.code === `auth/email-already-in-use` && <FormFeedback >O email já está cadastrado em outra conta.</FormFeedback> } 
          </FormGroup>

          <FormGroup>
            <Label for="username">Insira a senha</Label>
            <Input
              // value={passwordOne}
              innerRef={passwordOne}
              type="password"
              placeholder="Senha"
              onChange={event =>
                this.setState(byPropKey("passwordOne", event.target.value))
              }
              // invalid={error}
              invalid={ (error && error.code === 'auth/weak-password')  || this.state.validations.password.invalid}
            />
          </FormGroup>

          <FormGroup>
            <Label for="username">Confirmar senha</Label>
            <Input
              // value={passwordTwo}
              innerRef={passwordTwo}
              type="password"
              placeholder="Confirme a senha"
              onChange={event =>
                this.setState(byPropKey("passwordTwo", event.target.value))
              }
              invalid={ (error && error.code === 'auth/weak-password')  || this.state.validations.password.invalid}
            />
            <Input hidden={true} invalid />
            { error && error.code === `auth/weak-password` && <FormFeedback >A senha deve ter 6 ou mais digítos.</FormFeedback> } 
            
            { this.state.validations.password && this.state.validations.password.invalid && <FormFeedback >{this.state.validations.password.message}</FormFeedback> } 
          </FormGroup>
          
          
          
          {/* <Button type="submit" disabled={isInvalid}> */}
          <Button type="submit" onClick={() => this.onSubmit}>
            Criar conta
          </Button>

          {/* {error && <p> {error.message} </p>} */}
          
          
        </Form>
      </div>
    );
  }
}

const SignUpLink = () => (
  
  <div className="text-center">
    {/* <h5>Não tem uma conta ainda? <Link to={routes.SIGN_UP}> Crie uma agora</Link></h5> */}
    <Link className='btn btn-outline-orange-bnb my-2' to={routes.SIGN_UP}>Criar nova conta</Link>
  </div>
);

const ResetPasswordLink = () => (
  <div style={{ paddingTop: 20 }} >
  {/* <div style={{ paddingTop: 20 }} className="text-center"> */}
    {/* <Link to={routes.RESET_PASSWORD}>Esqueceu a senha? </Link> */}
    Esqueceu a senha? <Link to={routes.RESET_PASSWORD}>Redefinir senha</Link>
    
  </div>
);

export default withRouter(SignUpPage);

export { SignUpForm, SignUpLink, ResetPasswordLink };
