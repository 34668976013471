import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faMapMarkerAlt, faClock, faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import mainColors from '../../constants/color'

import ScrollManager from '../ScrollManager';
import FavButton from '../FavButton';

import { getAgendaAnyway, getAtividadeAnyway } from '../../services/agendaService'

import ReactLoading from 'react-loading';

import ReactGA from 'react-ga'

import PreloadImage from 'react-preload-image';
import './Atividade2.css'


import {Helmet} from "react-helmet";

import {
  WhatsappShareButton,
  WhatsappIcon
} from "react-share";
import AtividadeSimples from '../AtividadeSimples';


const diaLocal = (evento, index) => {

  let data = new Date(evento.dateTime.seconds * 1000);
  let dataString = 'Dia ' + moment(data).format('DD/MM/YYYY[,] dddd');
  let horas = moment(data).format('HH[h]');
  let minutos = moment(data).format('mm');

  const horaString = minutos !== '00' ? horas + minutos : horas;
  
  return (
    <div key={index} className='pt-1 py-2 activity_text'>
      <div><FontAwesomeIcon color={mainColors.orangeBNB} icon={faCalendar} fixedWidth/> {dataString}</div>
      <div><FontAwesomeIcon color={mainColors.orangeBNB} icon={faClock} fixedWidth/> {horaString}</div>
      <div><FontAwesomeIcon color={mainColors.orangeBNB} icon={faMapMarkerAlt} fixedWidth/> {evento.local}</div>
    </div>
  )

}


const tituloAtividade = (atividade) => {
  if (atividade.nome === atividade.grupoArtista) 
    return (
      <div className="activity_title">{atividade.nome} ({atividade.origemArtista})</div>
    )
  return (
    <div>
      <div className="activity_title">{atividade.nome}</div>
      <div className="activity_text">{atividade.grupoArtista} ({atividade.origemArtista})</div>
    </div>
  )
}


class Atividade extends Component {


  constructor(props) {
    super(props);
    this.state = {
      agenda: null, 
      atividade: null
    }
  }
  
  componentDidMount = async (prevProps, prevState) => {

    window.scrollTo(0, 0);
    let atividade = null
    let agenda = null
    let isFav = null
    
    if (this.props.location.state) {
      atividade = this.props.location.state.atividade;
      agenda    = this.props.location.state.agenda;
      isFav     = this.props.location.state.fav;
    }
      

    const { agendaId } = this.props.match.params;
    const { atividadeId } = this.props.match.params;
		

    if (!agenda || agenda === undefined) {
      agenda = await getAgendaAnyway(agendaId);
    }

    if (!atividade || atividade === undefined) {
      atividade = await getAtividadeAnyway(agendaId, atividadeId);
    }

    this.setState({
      agenda, 
      atividade: { ...atividade, idAtividade: atividade?.id, idAgenda: agenda?.id },
      isFav
    })

    ReactGA.event({
      category: 'Atividade',
      action: `Visit Atividade ${agenda.ccbnb.toUpperCase()} - ${agenda.nome.toUpperCase()}`,
      label: `${atividade.nome.toUpperCase()}`
    });
  }
  

  render() {

    const { atividade, agenda } = this.state;
    
    if (!atividade ||  !agenda || agenda === undefined || atividade === undefined ) {      
      return (
        <div style={{ minHeight: '80vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
          <ReactLoading type={'spin'} color={mainColors.orangeBNB} height={'20%'} width={'20%'} />  
        </div>        
      )

    }

    let complete_address = `https://agendaccbnb.com.br/agenda/${agenda.id}/atividade/${atividade.atividadeId}`

    return (
      <div className='activity_container py-3'>
          
        <Helmet>
          <title>Agenda CCBNB - {atividade.nome}</title>
          <meta name="title" property="og:title" content={`Agenda CCBNB - ${atividade.nome}`} />
          <meta name="description" property="og:description" content={atividade.release} />
          
          <meta name="image" property="og:image:secure_url" content={atividade.imagem} />
          <meta name="og:image" property="og:image" content={atividade.imagem} />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />


          <meta name="type" property="og:type" content="article" />
          <meta property="og:url" content={complete_address} />
        </Helmet>

        <Link 
            className='btn btn-sm btn-outline-orange-bnb mb-3'
            to={{
              pathname: `/agenda/${agenda.id}`,
              state: {
                agenda
              }
            }} 
          >
          <FontAwesomeIcon icon={faArrowLeft} />{' '}
          Voltar para Agenda
        </Link>

        <ScrollManager scrollKey={`atividade-${atividade.id}`} />

        {/* <div className='p-2 mb-3 container' style={{fontSize: 15}}> */}
        <div className='p-2 mb-2' style={{fontSize: 15, backgroundColor: 'white'}}>
          <AtividadeSimples fetching={this.props.modalFetching} error={this.props.modalError} agenda={agenda} atividade={atividade} isFav={false} />
        </div>

      </div>
      
    )
    
  }
}

export default withRouter(Atividade);