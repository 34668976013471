import ReactGA from 'react-ga'

const INITIAL_STATE = {
  agenda: undefined,
  byDay: undefined,
  byArea: undefined,
  days: undefined, 
  atividades: undefined
};


const applySetAgenda = (state, action) => {

  //analytics visit agenda!

  ReactGA.event({
    category: 'Agenda',
    action: `Visit Agenda ${action.agendaData.agenda.ccbnb.toUpperCase()}`,
    label: `${action.agendaData.agenda.nome.toUpperCase()}`
  });

  return ({
    ...state, 
    agenda: action.agendaData.agenda,
    byDay: action.agendaData.byDay,
    byArea: action.agendaData.byArea,
    atividades: action.agendaData.atividades, 
    days: action.agendaData.days
  });

}

const applyResetAgenda = (state, action) => {
  
  return ({
    ...INITIAL_STATE, 
  });

}


function agendaReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'AGENDA_UPDATE': {
      return applySetAgenda(state, action);
    }
    case 'AGENDA_RESET': {
      return applyResetAgenda(state);
    }
    default: return state;
  }
}

export default agendaReducer;