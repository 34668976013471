import React from 'react';
// import { messaging, auth } from './firebase';
import { messaging } from './firebase';
import { doAddUserToken } from './db';
import { toast } from 'react-toastify';
import NotificationAgenda from '../components/NotificationAgenda';
import firebase from 'firebase/compat/app';

console.log('On Message');

const isEnable = firebase.messaging.isSupported()

if (isEnable) {


  console.log("It's Enabled")
  // messaging
  //   .getToken()
  //   .then( (token) => console.log('TOKEN: ', token) )

  messaging.onMessage( function (payload) {
    console.log('chegou ahahah 2... ', payload);  
    
    // const { notification } = payload;
  
    // toast( <NotificationAgenda notification={notification} /> );
    
    toast( <NotificationAgenda payload={payload} /> );
    
    return payload;
  })
  // messaging.onTokenRefresh(() => {
  //   messaging.getToken().then((refreshedToken) => {
  //     console.log('Token refreshed.');
      
  //     // Indicate that the new Instance ID token has not yet been sent to the
  //     // app server.
  //     // setTokenSentToServer(false);
  //     // Send Instance ID token to app server.
  //     // sendTokenToServer(refreshedToken);
      
  //     const authUserId = auth.currentUser.uid;

  //     if (!!authUserId) {
  //       doAddUserToken(authUserId, refreshedToken);
  //     } else {
  //       console.log('no userid to update refreshedToken')
  //     }
        
      
  //   }).catch((err) => {
  //     console.log('Unable to retrieve refreshed token ', err);
  //     // showToken('Unable to retrieve refreshed token ', err);
  //   });
  // });
  

}

export const askForPermissionToReceiveNotifications = async (authUserId) => {
  if (messaging) {
    try {

      console.log('getting token')
      // await messaging.requestPermission();
  
      const token = await messaging.getToken();
      console.log("🚀 ~ file: messaging.js:67 ~ askForPermissionToReceiveNotifications ~ token", token)
  
      doAddUserToken(authUserId, token);
  
      // console.log('token do usuário:', token);
  
      return token;
    } catch (error) {
      console.error(error);
    }
  }  
  }
  


// export const getToken = () => {
//   try {
//     return messaging.getToken();
//   } catch (error) {
//     return null;
//   }
// }