import React from 'react';

import * as S from './styles'

export default function AgendaAreaButtons({ onClickAction }) {
  return (
    <S.ContainerAreaButons>
      
      <S.Button onClick={()=> onClickAction('Artes Visuais')}>Artes Visuais</S.Button>
      <S.Button onClick={()=> onClickAction('Audiovisual')}>Cinema</S.Button>
      <S.Button onClick={()=> onClickAction('Humanidades')}>Humanidades</S.Button>
      <S.Button onClick={()=> onClickAction('Literatura')}>Literatura</S.Button>
      <S.Button onClick={()=> onClickAction('Música')}>Música</S.Button>
      <S.Button onClick={()=> onClickAction('Artes Cênicas')}>Teatro</S.Button>
    </S.ContainerAreaButons>
  );
}
