import React from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faMapMarkerAlt, faClock } from '@fortawesome/free-solid-svg-icons'
import mainColors from '../../constants/color';


const diaLocal = (evento, index) => {

  let data = new Date(evento.dateTime.seconds * 1000);
  let dataString = 'Dia ' + moment(data).format('DD/MM/YYYY[,] dddd');
  let horas = moment(data).format('HH[h]');
  let minutos = moment(data).format('mm');

  const horaString = minutos !== '00' ? horas + minutos : horas;
  
  return (
    <div key={index} className='pt-1 py-1' style={{fontSize: 15}}>
      <div><FontAwesomeIcon color={mainColors.orangeBNB} icon={faCalendar} fixedWidth/> {dataString}</div>
      <div><FontAwesomeIcon color={mainColors.orangeBNB} icon={faClock} fixedWidth/> {horaString}</div>
      <div><FontAwesomeIcon color={mainColors.orangeBNB} icon={faMapMarkerAlt} fixedWidth/> {evento.local}</div>
    </div>
  )

}


export default function AtividadeResultadoBusca(props) {

  const { item, index, agenda } = props;

  return (
    <div key={`ativ/${index}`} className='p-3 my-2' style={{ border: "1px solid white", borderRadius: "5px" , backgroundColor: "rgba(255,255,255, 0.9)" }}>
      <div><b>{item.nome}</b></div>
      <div>{item.programa}</div>
      { item.eventos.map((evento, index) => diaLocal(evento, index)) }
      <div className='row'>
        <div className="align-self-end col-md-6 col-sm-12">
          <div className="d-flex justify-content-between flex-wrap py-1">
            <div>
              <Link 
                to={{
                  pathname: `/agenda/${agenda.id}/atividade/${item.atividadeId}`,
                  state: {
                    atividade: item, 
                    agenda: agenda, 
                  }
                }} 

                className="btn btn-outline-primary btn-sm">
                + Detalhes
              </Link>
            </div>
            {/* <div>
              <FavButton
                agenda={agenda}
                atividade={item}
              />
            </div> */}
          </div>
          
        </div>
      </div>
      
    </div>  
  )
}
