import React, { Component } from 'react';

import { connect } from "react-redux";

import 'moment/locale/pt-br';


import { InputGroup, InputGroupAddon, Input } from 'reactstrap';

import { ativsByArea } from './AgendaHelpers'
import { debounce } from 'lodash';
import ResultsByArea from './AgendaBusca/ResultsByArea';
import BuscarInput from './BuscarInput';



const replaceSpecialChars = (str) => {
    str = str.replace(/[ÀÁÂÃÄÅ]/,"A");
    str = str.replace(/[àáâãäå]/,"a");
    str = str.replace(/[ÈÉÊË]/,"E");
    str = str.replace(/[ÍÌÎ]/,"I");
    str = str.replace(/[íìî]/,"i");
    str = str.replace(/[ÓÒÕÔ]/,"O");
    str = str.replace(/[óòõô]/,"o");
    str = str.replace(/[ÚÙÛ]/,"U");
    str = str.replace(/[úùû]/,"u");
    str = str.replace(/[Ç]/,"C");
    str = str.replace(/[ç]/,"c");
    // o resto

    return str.replace(/[^a-z0-9]/gi,''); 
}



  
class AgendaPorArea extends Component {

  constructor(props) {
    super(props);

    this.state = {
      inputText: ''
    }

    this.onChange.bind(this)
    // delete the line above to fix scrollmanager
    // this.changeText = debounce(this.changeText, 400);
    this.changeText = debounce(this.changeTextSimple, 400);

    this.changeText.bind(this)

  }
  
  changeTextSimple = (text) => {
    
    // const { byArea, agenda, onSetSearch } = this.props;
    const { agenda, onSetSearch } = this.props;

    if ( text && text !== null && text !== '') {

      // let atividades = this.state.atividades;
      let atividades = this.props.atividades;
      
      let filtered = atividades.filter(a =>
        replaceSpecialChars(a.grupoArtista.toUpperCase()).includes(replaceSpecialChars(text.toUpperCase())) || 
        replaceSpecialChars(a.nome.toUpperCase()).includes(replaceSpecialChars(text.toUpperCase())) ||
        replaceSpecialChars(a.programa.toUpperCase()).includes(replaceSpecialChars(text.toUpperCase())) ||
        replaceSpecialChars(a.area.toUpperCase()).includes(replaceSpecialChars(text.toUpperCase()))
      );
      
      let byAreaFiltered = ativsByArea(filtered);

      this.setState({
        ...this.state, 
        byAreaFiltered: byAreaFiltered, 
        inputText: text
      });

      onSetSearch(agenda.id, text);

    } else {

      this.setState({
        ...this.state, 
        byAreaFiltered: null,
        inputText: ""
      });

      onSetSearch(agenda.id, "");

    }

  }

  onChange = (event) => {
    
    const text = event.target.value;

    this.setState({
      ...this.state, 
      inputText: text,
    })
    
    this.changeText(text);

    event.preventDefault()
    
  }

  


  componentDidMount = () => {
    
    const { searchs, agenda } = this.props;
    
    const search = searchs[agenda.id] || "";
    
    this.setState({
      ...this.state, 
      inputText: search,
    })

    this.changeTextSimple(search);

  }
  

  render() {
    
    const { byAreaFiltered } = this.state;

    const { agenda } = this.props;
    
      return (

        <div className="mb-5">
          <div className="my-3">
            <BuscarInput 
              onChange={this.onChange}
              inputTextValue={this.state.inputText}
              placeholder={'Digite aqui'}
              onClickClear={() => this.changeText('')}
            />

            {/* <InputGroup style={{zIndex: 1}}>
              
              <Input  
                onChange={this.onChange} 
                value={this.state.inputText} 
                placeholder={'Digite aqui'}
              />
              <InputGroupAddon addonType="append" onClick={() => this.changeText('')}>
                X
              </InputGroupAddon>
            </InputGroup> */}
          </div>
  

          <ResultsByArea byAreaFiltered={byAreaFiltered} agenda={agenda} />

          { 
            !byAreaFiltered && 
            <div className="container" style={{height: "calc(50vh)"}}>
              <div className="row h-100 justify-content-center align-items-center">
                <div style={{color: "grey", fontSize: 18.6}}>
                  {/* <p>Faça uma busca por nome:</p> */}
                  <p>Você pode buscar por:</p>
                  <ol>
                    <li>
                      <div><strong>Área</strong></div>
                      <small>exemplos: "música" ou "audiovisual"</small>
                    </li> 
                    <li>
                      <div><strong>Programa</strong></div>
                      <small>exemplos: "Embola Viola" ou "Clube do Leitor"</small>
                    </li> 
                    <li>
                      <div><strong>Nome do atividade</strong></div>
                      <small>exemplos: "Pintura Facial" ou "Xadrez"</small>
                    </li> 
                  </ol>
                </div>
              </div>
            </div>
          }

          
        </div>
      );
    

    
  }
}

const mapStateToProps = state => ({
  agenda: state.agendaState.agenda,
  byArea: state.agendaState.byArea,
  atividades: state.agendaState.atividades,
  searchs: state.searchState,
});

const mapDispatchToProps = (dispatch) => ({
  onSetSearch: (agenda, search) => dispatch({ type: 'SEARCH_SET', data: { agenda, search } } )
})

export default connect(mapStateToProps, mapDispatchToProps)(AgendaPorArea);
