import React from 'react'

import AtividadeResultadoBusca from './AtividadeResultadoBusca'
import mainColors from '../../constants/color';

const ResultsByArea = (props) => {
  // console.log("TCL: ResultsByArea -> props", props)

  const { byAreaFiltered, agenda } = props

  if ( !byAreaFiltered ) {
    return <></>
  }

  return (

    Object.keys(
      byAreaFiltered).map((area, index) => 
        <div key={`area` + area}>
          <div style={{backgroundColor: mainColors.orangeBNB , padding: 10, color: 'white', marginBottom: 10,}}>
            <div><strong>{area}</strong></div>
          </div>

          {
            byAreaFiltered[area].map(
              (item, index) => <AtividadeResultadoBusca index={index} item={item} agenda={agenda}  />
            )
          }
        </div>
      )
  )
}

export default ResultsByArea
