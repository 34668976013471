import React from 'react'

const PasswordForgetPage = () => {
  return (
    <div>
      <h1>Password Forget Page</h1>
    </div>
  )
}

export default PasswordForgetPage
