import React from 'react';
import AtividadeDestaque from '../AtividadeDestaque/AtividadeDestaque';
import "./AreaDestaques.css"


export default function AreaDestaques ({ nextActs }) {
  return (
    <div className="containerAreaDestaques">  
      { 
        nextActs && nextActs !== undefined && nextActs.map(act => {
          return <AtividadeDestaque key={act.key} item={act} index={act.key} idAgenda={act.atividade.idAgenda} />
        }) 
      }
    </div>
  );
}
