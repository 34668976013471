import styled, { css } from 'styled-components';
import media from "styled-media-query";

export const ModalBlock = styled.div`
  align-items: center;
  bottom: 0;
  justify-content: center;
  left: 0;
  /* padding: 0.4rem; */
  padding: 1.2rem;
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  /* opacity: 1; */
  
  opacity: ${({active}) => (active ? 1 : 0)};
  /* transition: all 0.25s ease-in-out; */
  
  /* transition: all .25s ease-in-out; */
  transition:  .25s ease-in-out;
  /* animation: slide-down 0.25s; */
  
  /* transition: all 0.5s ease 1; */
  
  /* display: ${({active}) => (active ? 'flex' : 'none')}; */

  z-index: ${({active}) => (active ? 4000 : -11)};
  /* z-index: 400; */
  /* cursor: auto; */

  ${media.lessThan("medium")`
    padding: 1.6rem;
  `}
`;

export const ModalOverlay = styled.a`
  /* background: rgba(247, 248, 249, 0.75); */
  background: rgba(247, 248, 249, 0.85);
  bottom: 0;
  cursor: default;
  display: block;
  left: 0;
  /* position: absolute; */
  position: fixed;
  right: 0;
  top: 0;
  /* inset: 0; */
  /* overflow-y: hidden; */
  z-index: 0;
`;

export const ModalClose = styled.a`
  float: right !important;
  text-decoration: none !important;
  cursor: pointer;
  font-size: 1rem;
`;

export const ModalContainer = styled.div`
  
  background: #ffffff;
  border-radius: 0.1rem;
  display: flex;
  flex-direction: column;
  /* max-height: 75vh; */
  max-width: 850px;
  padding: 0 0.8rem;
  width: 100%;
  height: 100%;
  /* height: calc(100% - 80px); */
  /* opacity: 0; */
  /* animation: slide-down 0.8s ease 1; */
  /* animation: slide-down 4s; */
  /* transition: all .5s ease-in-out; */
  /* transition: all 1s ease-in-out; */
  z-index: 10;
  box-shadow: 0 0.2rem 0.5rem rgba(48, 55, 66, 0.3);
  /* margin-top: 100px; */

  /* &:not( ??? ): hover{ 
    overflow: hidden;
  } */

  /* ${props => !props.active && css`
        :hover {
          color: red;
        }`
    } */

`;

export const ModalBody = styled.div`
  overflow-y: auto;
  /* padding: 30px 10px; */
  position: relative;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #303742;
  /* padding: 20px 5px 10px 5px; */
  padding: 10px 5px 10px 5px;
`;

export const ModalTitle = styled.span`
  font-size: 30px;
  font-weight: 500;
`;

export const ModalFooter = styled.div`
  /* padding: 10px 0px; */
  padding: 20px 20px;
  text-align: right;
`;

export const Button = styled.button`
  background: #7b2cbf;
  color: white;
  font-size: 1em;
  margin: 10px;
  padding: 5px 10px;
  border: 2px solid #7b2cbf;
  border-radius: 3px;
  cursor: pointer;
`;
