import { userConstants } from '../constants/usersConstants';
import { auth } from "../firebase/index";
import * as routes from "../constants/routes";
import { firebasedb } from '../firebase';
import { history } from '../constants/history'
import ReactGA from 'react-ga'


export const loginRequest = () => ({
  type: userConstants.LOGIN_REQUEST
})

export const loginSuccess = (authUser) => ({
  type: userConstants.LOGIN_SUCCESS, 
  authUser
})


export const saveUser = (authUser) => 
  (dispatch) => {
    localStorage.setItem('user', JSON.stringify(authUser))
    dispatch(loginSuccess(authUser))
  }


export const loginFailure = (error) => ({
  type: userConstants.LOGIN_FAILURE,
  error
})

export const logoutSimple = () => ({
  type: userConstants.LOGOUT
})

export const logout = () => 
  (dispatch) => {
    localStorage.removeItem('user')
    dispatch(logoutSimple())
  }

export const isAValidUser = (completUser) => {
  
  if ( !completUser.username || !completUser.ccbnb ) return false
  if ( !!completUser.username && completUser.username.trim() === '' ) return false
  if ( !!completUser.ccbnb && completUser.ccbnb.trim() === '' ) return false

  return true;
}

  
const getUserInfos = (authResult, redirectToActivity) => 


  (dispatch) => {
    
    firebasedb.onceGetUser(authResult.user.uid)
    .then( 
      (snapshot) => {
        // console.log("TCL: snapshot ->>>>>>>>>", snapshot)

        if (snapshot.exists()) {

          const basicUser = authResult.user;
          const completUserInfos = snapshot.val()
          
          //removing tokens and get all the rest
          const { tokens, ...userInfos } = completUserInfos;
          const completUser = { uid: basicUser.uid, ...userInfos };

          dispatch(loginSuccess(completUser))
          // console.log("TCL: completUser", completUser)

          console.log('SALVANDO AQUI 1')
          localStorage.setItem('user', JSON.stringify(completUser))

          if ( isAValidUser(completUser) ) {
            
            // console.log('VALID USUÁRIO')
            
            if ( redirectToActivity && redirectToActivity !== '/' ){
              history.push(redirectToActivity)
            } else {
              if (completUser?.ccbnb) {
                history.push({
                  pathname: `/ccbnb/${completUser.ccbnb}`,
                  state: {
                    ccbnb: completUser.ccbnb,
                    index: completUser.ccbnb,
                  }
                });
              } else {
                
                history.push(routes.LANDING);
              }

            }

          } else {
            // console.log('INVALID USUÁRIO')
            const infos = extractInfosNewUser(authResult)
            dispatch(loginSuccess(infos)) 
            history.push(routes.PROFILE)

          }

        } else {
          // console.log('NAO EXISTE ESSE USUÁRIO')
          history.push(routes.PROFILE);
        }

        
      }
    )
    // .catch( (error) => {
    //   console.log('aconteceu um ERRO: ', error)
    // });
  }

const extractInfosNewUser = (authResult) => {
  // console.log("TCL: authResult", authResult)  
  let result = { uid: authResult.user.uid }
  if ( !!authResult.user ) {
    if ( !!authResult.user.displayName ) 
      result = { ...result, username: authResult.user.displayName }
    if ( !!authResult.user.email ) 
      result = { ...result, email: authResult.user.email }
  }
  return result;
}



export const redirectLogin = (authResult, redirectToActivity) => 



  (dispatch) => {
    // console.log("TCL: redirectToActivity", redirectToActivity)
    // console.log("TCL: authResult AHAHAHAHAHAH", authResult)

    ReactGA.event({
      category: 'User',
      action: 'Login'
    });

    if (!!authResult.additionalUserInfo.isNewUser) {

      //new user redirected
      // console.log(' ---->>>>>> VAI PARA PROFILE')
      
      const infos = extractInfosNewUser(authResult)
      console.log("TCL: infos", infos)

      dispatch(loginSuccess(infos)) 

      history.push(routes.PROFILE)


    } else {
      dispatch(getUserInfos(authResult, redirectToActivity))
    }    

  }




export const login = (email, password, redirectToActivity) => 
  
   (dispatch) => {

    dispatch(loginRequest());

    ReactGA.event({
      category: 'User',
      action: 'Login'
    });

    auth
      .doSignInWithEmailAndPassword(email, password)
      .then(
        user => {
        
        firebasedb.onceGetUser(user.user.uid)
          .then( 
            (snapshot) => {
              
              const basicUser = user.user;
              const completUserInfos = snapshot.val()
              
              //removing tokens and get all the rest
              const { tokens, ...userInfos } = completUserInfos;
              
              const completUser = { uid: basicUser.uid, ...userInfos };

              console.log('SALVANDO AQUI 2')
              localStorage.setItem('user', JSON.stringify(completUser))
              
              dispatch(loginSuccess(completUser))
              
              if ( redirectToActivity ){
                history.push(redirectToActivity)
              } else {
                const ccbnb = completUser.ccbnb.toLowerCase()
                history.push({
                  pathname: `/ccbnb/${ccbnb}`,
                  state: {
                    ccbnb: ccbnb,
                    index: ccbnb,
                  }
                });
              }
            }
          );
          
      })
      .catch(error => {
        console.error('erro no login')
        dispatch(loginFailure(error));
      });

  }


export const breadCombReset = () => ({
    type: userConstants.BREADCOMB_RESET
  })
  
export const saveBreadcombCCBNB = (ccbnb) => ({
  type: userConstants.BREADCOMB_SUCCESS_CCBNB, 
  ccbnb
})

// export const saveBreadcombAgenda = (agenda) => ({
//   type: userConstants.BREADCOMB_SUCCESS_AGENDA, 
//   agenda
// })