import moment from 'moment';
import 'moment/locale/pt-br';

// function agenda mensal
export const agendaMensal = (days, ativByDays) => {
      
  return days.map((day) => {
    
    // let aDate = moment(day.data.seconds * 1000).toDate();
    let aDate = day.data.toDate();
    let dateString = moment(aDate).format('DD/MM/YYYY');
    
    let result = {
      dateTime: aDate,
      dateString: dateString,
      aberto: day.aberto, 
    }

    result = day.detalhe ?  { ...result, detalhe: day.detalhe } : result;

    result = ativByDays[dateString] ? { ...result, atividades: ativByDays[dateString] } : result;
    
    return result;
  })
  .sort((a, b) => {
    let stringA = moment(a.dateTime).format('MM DD YYYY HH:mm');
    let stringB = moment(b.dateTime).format('MM DD YYYY HH:mm');
    return stringA.localeCompare(stringB);
  });

}

export const ativsByArea =  (atividades) => {

  // reduce to array containing all events
  let result = atividades
  .reduce((val, element) => {
    (val[element.area] = val[element.area] || []).push(element);
    return val;
  }, {})

  Object.keys(result).forEach(area => {
    result[area].sort((a, b) => {
      return a.nome.localeCompare(b.nome);
    });
  })
  return result;
}


// function atividades por dia
export const ativsByDay =  (atividades) => {

  // reduce to array containing all events
  let result = atividades.reduce((val, element) => {        
    if (element.eventos) {
      
      element.eventos.forEach(e => {
        val = [...val, { 
          atividadeId: element.atividadeId,
          atividade: element, 
          evento: { 
            // dateTime: moment(e.dateTime.seconds * 1000).toDate(), 
            dateTime: e.dateTime.toDate(),
            local: e.local
          } 
        } ]
      })
    }
    return val;
  }, [])
  
  .sort((a, b) => {
    let stringA = moment(a.evento.dateTime).format('MM DD YYYY HH:mm');
    let stringB = moment(b.evento.dateTime).format('MM DD YYYY HH:mm');
    return stringA.localeCompare(stringB);
  })
  
  .reduce((val, element) => {
    let aDate = moment(element.evento.dateTime).format('DD/MM/YYYY');
    (val[aDate] = val[aDate] || []).push(element);
    return val;
  }, {});

  return result;

}
