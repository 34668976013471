// import React from "react"
import React, { Component } from 'react'
// import firebase from 'firebase'
import firebase from 'firebase/compat/app'
// import { auth } from '../firebase/auth';

import { redirectLogin } from '../actions/sessionActions'
import { connect } from "react-redux"
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
// import StyledFirebaseAuth from 'react-firebaseui'
// import { withRouter } from "react-router-dom";


const fAuth = firebase.auth();
firebase.auth().languageCode = 'pt-br'
// firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
fAuth.languageCode = 'pt-br'

let uiConfig;

class Entrar extends Component {

  constructor(props) {
    super(props)

    uiConfig = {
      signInFlow: 'redirect',
      // signInSuccessUrl: '/',
      // redirectUrl: '/',
      // signInSuccessUrl: redirectUrl,
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID
      ],
      callbacks: {
        signInSuccessWithAuthResult: function (authResult, redirectUrl) {
          
          const link = localStorage.getItem("redirecdtLink") || '/';
          localStorage.removeItem("redirecdtLink")
    
          props.onRedirectLogin(authResult, link)
        }
      }
    
    };

  
  }

  
  mudarNomes = () => {
    const buttonGoogle = document.querySelector(".firebaseui-idp-google .firebaseui-idp-text-long")
    const buttonEmail = document.querySelector(".firebaseui-idp-password .firebaseui-idp-text-long")

    if (!!buttonGoogle) buttonGoogle.innerHTML = 'Entrar com Google'
    if (!!buttonEmail) buttonEmail.innerHTML = 'Entrar com email'
  
  }

  

  componentDidMount() {    

    setTimeout( () => {

      // const buttonGoogle = document.querySelector(".firebaseui-idp-google .firebaseui-idp-text-long")
      // const buttonEmail = document.querySelector(".firebaseui-idp-password .firebaseui-idp-text-long")
  
      // if (!!buttonGoogle) buttonGoogle.innerHTML = 'Entrar com Google'
      // if (!!buttonEmail) buttonEmail.innerHTML = 'Entrar com email'

      this.mudarNomes();
    

    }, 1)


  }

  render() {
    return (
      <div  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: 'calc(100vh - 60px)' }}>     
        <StyledFirebaseAuth 
          uiConfig={uiConfig} 
          firebaseAuth={fAuth} />
      </div>
    )
  }
}




// const Entrar = (props) => {

//   // const redirectUrl = localStorage.getItem("redirecdtLink") || '/';

//   const uiConfig = {
//     signInFlow: 'redirect',
//     // signInSuccessUrl: '/',
//     // redirectUrl: '/',
//     // signInSuccessUrl: redirectUrl,
//     signInOptions: [
//       firebase.auth.GoogleAuthProvider.PROVIDER_ID,
//       firebase.auth.EmailAuthProvider.PROVIDER_ID
//     ],
//     callbacks: {
//       signInSuccessWithAuthResult: function (authResult, redirectUrl) {
//         // console.log("TCL: redirectUrl 11111", redirectUrl)
//         // console.log("TCL: USERRR 22222", authResult)

//         // const link = localStorage.getItem("redirecdtLink") || '/';
        
//         // localStorage.removeItem("redirecdtLink")

//         const link = localStorage.getItem("redirecdtLink") || '/';
//         localStorage.removeItem("redirecdtLink")

//         props.onRedirectLogin(authResult, link)
//       }
//     }
  
//   };

//   const fAuth = firebase.auth();
//   // firebase.auth().languageCode = 'pt-br'
//   // fAuth.languageCode = 'pt-br'

//   return (
//     <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: 'calc(100vh - 60px)' }}>     
//       <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={fAuth} />
//     </div>
//   );
// };


const mapStateToProps = state => ({
  error: state.sessionState.error
})

const mapDispatchToProps = (dispatch) => ({
  onRedirectLogin: (user, link) => dispatch( redirectLogin(user, link) ),
});

// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Entrar));
export default connect(mapStateToProps, mapDispatchToProps)(Entrar);
